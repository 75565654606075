import avatar from "../src/images/avatar.svg"
export const APP_CONFIG = process.env.REACT_APP_ENV === "production" ?
    {
        apiPath: "https://www.politiq.net/api",
        recaptchaSiteKey: "6Lf_iFwUAAAAAKTVxEHwzhxnSPODjMyJVY6a5zMq",
        urlPreviewKey: "59f18cb52153e3908e5cabf60a6fa1494dc08c25f62e0",
        previewApiUrl: "https://api.linkpreview.net",
        defaultAvatar: "https://www.politiq.net/images/people/avatar.png",
    } : {
        apiPath: "https://dev.politiq.net/api",
        recaptchaSiteKey: "6Lf_iFwUAAAAAKTVxEHwzhxnSPODjMyJVY6a5zMq",
        urlPreviewKey: "59f18cb52153e3908e5cabf60a6fa1494dc08c25f62e0",
        previewApiUrl: "https://api.linkpreview.net",
        defaultAvatar: avatar,
    }