import {
    ORGS_COMMENTS_LIST_LOADED,
    ORGS_COMMENTS_LIST_UNLOADED,
    ORGS_ADD_COMMENT_TO_COMMENTS_LIST
} from '../constants/actionTypes';

export default (state = { list: [] }, action) => {
    switch (action.type) {
        case ORGS_COMMENTS_LIST_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case ORGS_ADD_COMMENT_TO_COMMENTS_LIST:
            return {
                ...state,
                list: action.payload
            }
        case ORGS_COMMENTS_LIST_UNLOADED:
            return {};
        default:
            return state;
    }
};
