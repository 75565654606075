import {
  FETCH_REVIEW_FINISHED
} from '../constants/actionTypes';

const defaultState = {
  review : {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REVIEW_FINISHED:
      return {
        ...state,
        review: action.payload
      }
    default:
      return state;
  }
};
