import {
  POST_FAVORITED,
  POST_UNFAVORITED,
  SET_PAGE,
  APPLY_TAG_FILTER,
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  CHANGE_TAB,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  MYSTUFF_POSTS_TAB_LOADED,
  MYSTUFF_POSTS_TAB_UNLOADED,
  POST_LOAD_MORE,
  FETCH_POSTS_START,
  FETCH_POSTS_FINISHED
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case POST_FAVORITED:
    case POST_UNFAVORITED:
      return {
        ...state,
        posts: state.posts.map(post => {
          if (post.slug === action.payload.article.slug) {
            return {
              ...post,
              favorited: action.payload.article.favorited,
              favoritesCount: action.payload.article.favoritesCount
            };
          }
          return post;
        })
      };
    case SET_PAGE:
      return {
        ...state,
        posts: action.payload,
        postsCount: action.payload.articlesCount,
        currentPage: action.page
      };
    case APPLY_TAG_FILTER:
      return {
        ...state,
        pager: action.pager,
        posts: action.payload,
        postsCount: action.payload.articlesCount,
        tab: null,
        tag: action.tag,
        currentPage: 0
      };
    case HOME_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload ? action.payload[0] && action.payload[0].tags || [] : null,
        posts: Array.isArray(action.payload) ? action.payload : [],
        postsCount: action.payload && action.payload.length && action.payload[0].articlesCount ? action.payload[1].articlesCount : null,
        currentPage: 0,
        tab: action.tab,
        hasMore: true,
        loadMorePage: 1
      };
    case PROFILE_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload ? action.payload[0] && action.payload[0].tags || [] : null,
        posts: action.payload ? action.payload : null,
        postsCount: action.payload && action.payload.length && action.payload[0].articlesCount ? action.payload[1].articlesCount : null,
        currentPage: 0,
        tab: action.tab,
        hasMore: true,
        loadMorePage: 1
      };
    case MYSTUFF_POSTS_TAB_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload ? action.payload[0] && action.payload[0].tags || [] : null,
        posts: action.payload ? (JSON.stringify([{}, null]) === JSON.stringify(action.payload) ? [] : action.payload) : null,
        postsCount: action.payload && action.payload.length && action.payload[0].articlesCount ? action.payload[1].articlesCount : null,
        currentPage: 0,
        tab: action.tab,
        hasMore: true,
        loadMorePage: 1
      };
    case HOME_PAGE_UNLOADED:
      return {};
    case CHANGE_TAB:
      return {
        ...state,
        pager: action.pager,
        posts: action.payload,
        postsCount: action.payload.articlesCount,
        tab: action.tab,
        currentPage: 0,
        tag: null,
        hasMore: true,
        loadMorePage: 1
      };
    case POST_LOAD_MORE:
      let newPosts = Array.isArray(state.posts) ? state.posts.slice() : [];
      return {
        ...state,
        posts: newPosts.concat(action.payload),
        hasMore: !!action?.payload?.length,
        loadMorePage: (state.loadMorePage || 0) + 1
      };

    case FETCH_POSTS_START:
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case FETCH_POSTS_FINISHED:
      return {
        ...state,
        posts: !action.payload.error ? action.payload : null,
        fetching: false,
        fetched: true,
        currentPage: 0
      };
    case PROFILE_PAGE_UNLOADED:
    case MYSTUFF_POSTS_TAB_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
