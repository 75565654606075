import { FETCH_PAGE_STARTED, FETCH_PAGE_FINISHED } from '../constants/actionTypes';

const initialState = {
  page: {},
  fetched: false,
  fetching: false,
  isPollyPage: false
}

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAGE_STARTED:
      return {
        ...state,
        fetched: false,
        fetching: true,
        page: {}
      };
    case FETCH_PAGE_FINISHED:
      const local = localStorage.getItem('language');
      const language = action.payload?.language;
      if(!local && language){
        localStorage.setItem('language', language);
        window.location.reload()
      }
      return {
        isPollyPage: true,
        fetched: true,
        fetching: false,
        page: action.payload
      };
    default:
      return state;
  }
};
