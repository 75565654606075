import {
  COMMENTS_LIST_LOADED,
  COMMENTS_LIST_UNLOADED,
  ADD_COMMENT_TO_COMMENTS_LIST,
    TOPIC_STANCE_COMMENTS_LIST_LOADED,
    TOPIC_STANCE_ADD_COMMENT_TO_COMMENTS_LIST,
    TOPIC_STANCE_COMMENTS_LIST_UNLOADED
} from '../constants/actionTypes';

export default (state = { list: [],topicStanceList:[] }, action) => {
  switch (action.type) {
    case COMMENTS_LIST_LOADED:
      return {
        ...state,
        list: action.payload
      };
      case TOPIC_STANCE_COMMENTS_LIST_LOADED:
      return {
        ...state,
        topicStanceList: action.payload
      };
    case ADD_COMMENT_TO_COMMENTS_LIST:
      return {
        ...state,
        list: action.payload
      }
      case TOPIC_STANCE_ADD_COMMENT_TO_COMMENTS_LIST:
      return {
        ...state,
          topicStanceList: action.payload
      }
      case COMMENTS_LIST_UNLOADED:
      return {
          ...state,
          list:{}
      };
      case TOPIC_STANCE_COMMENTS_LIST_UNLOADED:
      return {
          ...state,
          topicStanceList:{}
      };
    default:
      return state;
  }
};
