import {
    TOPIC_STANCE_PAGE_LOAD_MORE,
    TOPIC_STANCE_PAGE_UNLOADED,
} from '../constants/actionTypes';

const initialState ={
    topicStances:[],
    hasMore:true,
    loadMorePage:0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case TOPIC_STANCE_PAGE_LOAD_MORE:
            let newTopicStances = Array.isArray(state.topicStances) ? state.topicStances.slice() : [];
            return {
                ...state,
                topicStances: newTopicStances.concat(action.payload),
                hasMore: (action?.payload?.length > 9),
                loadMorePage: (state.loadMorePage || 0) + 1
            };
        case TOPIC_STANCE_PAGE_UNLOADED:
            return {};
        default:
            return state;
    }
};
