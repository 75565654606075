import {
  MYSTUFF_FOLLOWING_TAB_LOADED,

  MYSTUFF_FOLLOWING_TAB_UNLOADED,
  FETCH_FOLLOWING_FINISHED,
  FOLLOW_USER,
  UNFOLLOW_USER
} from '../constants/actionTypes';

const initialState = {
  persons: [],
  isSuccess: false,
  isUnfollowed: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MYSTUFF_FOLLOWING_TAB_LOADED:
      return {
        ...state,
        persons: action.payload[1]
      };
    case MYSTUFF_FOLLOWING_TAB_UNLOADED:
      return {};
    case FETCH_FOLLOWING_FINISHED:
      return {
        ...state,
        persons: action.payload,
        isUnfollowed: false
      }
    case FOLLOW_USER:
      return {
        ...state,
        ...action.payload,
        isSuccess: true
      }
    case UNFOLLOW_USER:
      return {
        ...state,
        ...action.payload,
        isUnfollowed: true
      }
    default:
      return state;
  }
};
