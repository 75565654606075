export const APP_LOAD = 'APP_LOAD';
export const APP_LOADING = 'APP_LOADING';
export const REDIRECT = 'REDIRECT';
export const FETCH_MENTION_STARTED = 'FETCH_MENTION_STARTED';
export const FETCH_MENTION_FINISHED = 'FETCH_MENTION_FINISHED';
export const SETTINGS_LOADED = 'SETTINGS_LOADED';
export const SETTINGS_UNLOADED = 'SETTINGS_UNLOADED';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const SETTINGS_SAVED = 'SETTING_SAVED';
export const POST_SUBMITTED = 'POST_SUBMITTED';
export const TOPIC_STANCE_SUBMITTED = 'TOPIC_STANCE_SUBMITTED';
export const DELETE_POST = 'DELETE_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const POST_PAGE_LOADED = 'POST_PAGE_LOADED';
export const POST_PAGE_UNLOADED = 'POST_PAGE_UNLOADED';
export const TOPIC_STANCE_COMMENTS_LIST_UNLOADED = 'TOPIC_STANCE_COMMENTS_LIST_UNLOADED';
export const COMMENTS_LOADED = 'COMMENTS_LOADED';
export const TOPIC_STANCE_COMMENTS_LOADED = 'TOPIC_STANCE_COMMENTS_LOADED';
export const COMMENTS_UNLOADED = 'COMMENTS_UNLOADED';
export const TOPIC_STANCE_COMMENTS_UNLOADED = 'TOPIC_STANCE_COMMENTS_UNLOADED';
export const COMMENTS_LIST_LOADED = 'COMMENTS_LIST_LOADED';
export const TOPIC_STANCE_COMMENTS_LIST_LOADED = 'TOPIC_STANCE_COMMENTS_LIST_LOADED';
export const COMMENTS_LIST_UNLOADED = 'COMMENTS_LIST_UNLOADED';
export const COMMENT_CREATED = 'COMMENT_CREATED';
export const TOPIC_STANCE_COMMENT_CREATED = 'TOPIC_STANCE_COMMENT_CREATED';
export const POST_LOAD_MORE = 'POST_LOAD_MORE';
export const TOPIC_STANCE_PAGE_UNLOADED = 'TOPIC_STANCE_PAGE_UNLOADED';
export const TOPIC_STANCE_PAGE_LOAD_MORE = 'TOPIC_STANCE_PAGE_LOAD_MORE';
export const TOPIC_LOAD_MORE = 'TOPIC_LOAD_MORE';
export const TOPIC_DELETE = 'TOPIC_DELETE';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ADD_COMMENT_TO_COMMENTS_LIST = 'ADD_COMMENT_TO_COMMENTS_LIST';
export const TOPIC_STANCE_ADD_COMMENT_TO_COMMENTS_LIST = 'TOPIC_STANCE_ADD_COMMENT_TO_COMMENTS_LIST';
export const POST_FAVORITED = 'POST_FAVORITED';
export const POST_UNFAVORITED = 'POST_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const TOPIC_EDITOR_PAGE_LOADED = 'TOPIC_EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const TOPIC_EDITOR_PAGE_UNLOADED = 'TOPIC_EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const TAGS_SUGGESTIONS_LOADED = 'TAGS_SUGGESTIONS_LOADED';
export const TAGS_SUGGESTIONS_UNLOADED = 'TAGS_SUGGESTIONS_UNLOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';
export const FETCH_USER_SESSION_STARTED = 'FETCH_USER_SESSION_STARTED';
export const FETCH_USER_SESSION_FINISHED = 'FETCH_USER_SESSION_FINISHED';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const TOPIC_UPDATE_FIELD_EDITOR = 'TOPIC_UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const POLLY_LIST_SEARCH = 'POLLY_LIST_SEARCH';
export const POLLY_LIST_PAGE_LOADED = 'POLLY_LIST_PAGE_LOADED';
export const POLLY_LIST_SIDEBAR_LOADED = 'POLLY_LIST_SIDEBAR_LOADED';
export const GET_HOT_TOPICS = 'GET_HOT_TOPICS';
export const POLLY_LIST_PAGE_UNLOADED = 'POLLY_LIST_PAGE_UNLOADED';
export const POLLY_LIST_SIDEBAR_UNLOADED = 'POLLY_LIST_SIDEBAR_UNLOADED';
export const POLLY_PAGE_LOADED = 'POLLY_PAGE_LOADED';
export const POLLY_PAGE_UNLOADED = 'POLLY_PAGE_UNLOADED';
export const POLLY_ADD_COMMENTS = 'POLLY_ADD_COMMENTS';
export const POLLY_NEWS_LOADED = 'POLLY_NEWS_LOADED';
export const POLLY_COMMENTS_USER_INFO_LOAD = 'POLLY_COMMENTS_USER_INFO_LOAD';
export const POLLY_FOLLOW = 'POLLY_FOLLOW';
export const POLLY_UN_FOLLOW = 'POLLY_UN_FOLLOW';
export const POLLY_LOAD_MORE = 'POLLY_LOAD_MORE';

export const FETCH_ELECTION_LIST = 'FETCH_ELECTION_LIST';
export const FETCH_ELECTION_CANDIDATES_LIST = 'FETCH_ELECTION_CANDIDATES_LIST';
export const ELECTION_PAGE_LOAD = 'ELECTION_PAGE_LOAD'
export const ELECTION_COMMENTS_LOADING = 'ELECTION_COMMENTS_LOADING'
export const ELECTION_COMMENTS_LOADED = 'ELECTION_COMMENTS_LOADED'
export const ELECTION_NEWS_LOADED = 'ELECTION_NEWS_LOADED'
export const ELECTION_COMMENTS_USER_INFO_LOAD = 'ELECTION_COMMENTS_USER_INFO_LOAD'
export const RELATED_ELECTION_LOADING = 'RELATED_ELECTION_LOADING'
export const RELATED_ELECTION_LOADED = 'RELATED_ELECTION_LOADED'

export const POLLY_COMMENTS_LOADED = 'POLLY_COMMENTS_LOADED';
export const POLLY_COMMENTS_LOADING = 'POLLY_COMMENTS_LOADING';
export const RELATED_POLLY_LOADING = 'RELATED_POLLY_LOADING';
export const RELATED_POLLY_LOADED = 'RELATED_POLLY_LOADED';

export const POLLY_VOTES_LOADED = 'POLLY_VOTES_LOADED';
export const POLLY_VOTES_UNLOADED = 'POLLY_VOTES_UNLOADED';
export const MYSTUFF_POSTS_TAB_LOADED = 'MYSTUFF_POSTS_TAB_LOADED';
export const MYSTUFF_POSTS_TAB_UNLOADED = 'MYSTUFF_POSTS_TAB_UNLOADED';
export const MYSTUFF_TOPIC_STANCE_TAB_UNLOADED = 'MYSTUFF_TOPIC_STANCE_TAB_UNLOADED';
export const MYSTUFF_FOLLOWING_TAB_LOADING = 'MYSTUFF_FOLLOWING_TAB_LOADING';
export const MYSTUFF_FOLLOWING_TAB_LOADED = 'MYSTUFF_FOLLOWING_TAB_LOADED';
export const MYSTUFF_FOLLOWING_TAB_UNLOADED = 'MYSTUFF_FOLLOWING_TAB_UNLOADED';
export const MYSTUFF_FOLLOWERS_TAB_LOADED = 'MYSTUFF_FOLLOWERS_TAB_LOADED';
export const MYSTUFF_FOLLOWERS_TAB_UNLOADED = 'MYSTUFF_FOLLOWERS_TAB_LOADED';
export const MYSTUFF_FRIENDS_TAB_LOADED = 'MYSTUFF_FRIENDS_TAB_LOADED';
export const MYSTUFF_FRIENDS_TAB_UNLOADED = 'MYSTUFF_FRIENDS_TAB_UNLOADED';
export const FETCH_FOLLOWERS_STARTED = 'FETCH_FOLLOWERS_STARTED';
export const FETCH_FOLLOWERS_FINISHED = 'FETCH_FOLLOWERS_FINISHED';
export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_TOPIC_STANCES_START = "FETCH_TOPIC_STANCES_START";
export const FETCH_POSTS_FINISHED = "FETCH_POSTS_FINISHED";
export const FETCH_TOPIC_STANCES_FINISHED = "FETCH_TOPIC_STANCES_FINISHED";
export const FETCH_AUDIENCES_STARTED = "FETCH_AUDIENCES_STARTED";
export const FETCH_AUDIENCES_LOADED = "FETCH_AUDIENCES_LOADED";

export const MEMBER_LOAD = "MEMBER_LOAD";
export const MEMBER_LOAD_MORE = "MEMBER_LOAD_MORE";
export const MEMBER_UNLOADED = "MEMBER_UNLOADED";

//interview
export const FETCH_REVIEW_FINISHED = 'FETCH_REVIEW_FINISHED';

//quick post
export const ON_CHANGE_QUICK_POST = "ON_CHANGE_QUICK_POST";
export const CLEAR_QUICK_POST = "CLEAR_QUICK_POST";

//profile
export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_FINISHED = "FETCH_PROFILE_FINISHED";
export const FETCH_USER_STATUS = "FETCH_USER_STATUS";
export const UPDATE_CURRENT_USER_ERROR = "UPDATE_CURRENT_USER_ERROR";

//user
export const FETCH_USER_AVATAR_START = "FETCH_USER_AVATAR_START";
export const FETCH_USER_AVATAR_FINISHED = "FETCH_USER_AVATAR_FINISHED";
export const SET_ANONYMOUS_MODE="SET_ANONYMOUS_MODE"
export const UPLOAD_USER_AVATAR_START = "UPLOAD_USER_AVATAR_START";
export const UPLOAD_USER_AVATAR_FINISHED = "UPLOAD_USER_AVATAR_FINISHED";
export const UPDATE_CURRENT_USER_START = "UPDATE_CURRENT_USER_START";
export const UPDATE_CURRENT_USER_FINISHED = "UPDATE_CURRENT_USER_FINISHED";
export const FETCH_CURRENT_USER_START = "FETCH_CURRENT_USER_START";
export const FETCH_CURRENT_USER_FINISHED = "FETCH_CURRENT_USER_FINISHED";

//activation
export const ACTIVATION_START = "ACTIVATION_START";
export const ACTIVATION_FINISHED = "ACTIVATION_FINISHED";

//votes
export const VOTES_LOADING = "VOTES_LOADING";
export const VOTES_LOADED = "VOTES_LOADED";
export const AVERAGES_VOTES_LOADING = "AVERAGES_VOTES_LOADING";
export const AVERAGES_VOTES_LOADED = "AVERAGES_VOTES_LOADED";
export const VOTE_CREATED_SUCCESSFULLY = "VOTE_CREATED_SUCCESSFULLY";

//party votes

export const RELATED_PARTY_LOADED = 'RELATED_PARTY_LOADED';
export const RELATED_PARTY_LOADING = 'RELATED_PARTY_LOADING';

export const PARTY_COMMENTS_LOADING = 'PARTY_COMMENTS_LOADING';
export const PARTY_COMMENTS_LOADED = 'PARTY_COMMENTS_LOADED';

export const PARTIES_VOTE_CREATED_SUCCESSFULLY = "PARTIES_VOTE_CREATED_SUCCESSFULLY";
export const PARTIES_VOTES_LOADING = "PARTIES_VOTES_LOADING";
export const PARTIES_VOTES_LOADED = "PARTIES_VOTES_LOADED";

//following
export const FETCH_FOLLOWING_FINISHED = "FETCH_FOLLOWING_FINISHED";
export const FETCH_FOLLOWING_STARTED = "FETCH_FOLLOWING_STARTED";
export const FETCH_USER_FOLLOW_FINISHED = "FETCH_USER_FOLLOW_FINISHED";
export const FETCH_USER_FOLLOW_STARTED = "FETCH_USER_FOLLOW_STARTED";

//pages
export const FETCH_PAGE_STARTED = 'FETCH_PAGE_STARTED';
export const FETCH_PAGE_FINISHED = 'FETCH_PAGE_FINISHED';

//location
export const SET_LOCATION = 'SET_LOCATION';

//goals
export const GOAL_LOAD_MORE = 'GOAL_LOAD_MORE';
export const PROFILE_PAGE_GOALS_UNLOADED = 'PROFILE_PAGE_GOALS_UNLOADED';
export const MYSTUFF_GOALS_TAB_UNLOADED = 'MYSTUFF_GOALS_TAB_UNLOADED';
export const FETCH_GOALS_START = "FETCH_GOALS_START";
export const FETCH_GOALS_FINISHED = "FETCH_GOALS_FINISHED";
export const SET_GOAL_PAGE = 'SET_GOAL_PAGE';
export const GOAL_PAGE_LOADED = 'GOAL_PAGE_LOADED';
export const GOAL_PAGE_UNLOADED = 'GOAL_PAGE_UNLOADED';

export const GOALS_COMMENTS_LOADED = 'GOALS_COMMENTS_LOADED';
export const GOALS_COMMENTS_UNLOADED = 'GOALS_COMMENTS_UNLOADED';
export const GOALS_COMMENTS_LIST_LOADED = 'GOALS_COMMENTS_LIST_LOADED';
export const GOALS_COMMENTS_LIST_UNLOADED = 'GOALS_COMMENTS_LIST_UNLOADED';
export const GOALS_COMMENT_CREATED = 'GOALS_COMMENT_CREATED';
export const GOALS_ADD_COMMENT = 'GOALS_ADD_COMMENT';
export const GOALS_DELETE_COMMENT = 'GOALS_DELETE_COMMENT';
export const GOALS_ADD_COMMENT_TO_COMMENTS_LIST = 'GOALS_ADD_COMMENT_TO_COMMENTS_LIST';

//Abuse
export const ABUSE = "POST_ABUSE";

//blog
export const BLOG_PAGE_LOADED = 'BLOG_PAGE_LOADED';
export const BLOG_LOAD_MORE = 'BLOG_LOAD_MORE';

//ORGS

export const ORGS_COMMENTS_CREATED = 'ORGS_COMMENTS_CREATED';
export const ORGS_COMMENTS_LOADED = 'ORGS_COMMENTS_LOADED';
export const ORGS_COMMENTS_UNLOADED = 'ORGS_COMMENTS_UNLOADED';
export const ORGS_COMMENTS_LIST_LOADED = 'ORGS_COMMENTS_LIST_LOADED';
export const ORGS_COMMENTS_LIST_UNLOADED = 'ORGS_COMMENTS_LIST_UNLOADED';
export const ORGS_ADD_COMMENT_TO_COMMENTS_LIST = 'ORGS_ADD_COMMENT_TO_COMMENTS_LIST';

//max_counter
export const POST_MAX_CHARACTER = 500
export const COMMENT_MAX_CHARACTER = 300


export const TOPIC_STANCE_MAX_CHARACTER = 4000
