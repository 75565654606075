import {
  GOAL_PAGE_LOADED,
  GOAL_PAGE_UNLOADED,
  GOALS_ADD_COMMENT,
  GOALS_DELETE_COMMENT
} from '../constants/actionTypes';

export default (state = { goal: {} }, action) => {
  switch (action.type) {
    case GOAL_PAGE_LOADED:
      return {
        ...state,
        goal: action.payload[0],
        goalError: action.payload.message
      };
    case GOAL_PAGE_UNLOADED:
      return {};
    case GOALS_ADD_COMMENT:
      return {
        ...state,
        commentErrors: action.error ? action.payload.errors : null,
        comments: action.error ?
          null :
          (state.comments || []).concat([action.payload.comment])
      };
    case GOALS_DELETE_COMMENT:
      const commentId = action.commentId;
      return {
        ...state,
        comments: state.comments.filter(comment => comment.id !== commentId)
      };
    default:
      return state;
  }
};
