import {
    ORGS_COMMENTS_CREATED,
    ORGS_COMMENTS_LOADED,
    ORGS_COMMENTS_UNLOADED
} from '../constants/actionTypes';

export default (state = { comments: [] }, action) => {
    switch (action.type) {
        case ORGS_COMMENTS_CREATED:
            return {
                ...state,
                comment: action.payload
            };
        case ORGS_COMMENTS_LOADED:
            return {
                ...state,
                comments: action.payload
            };
        case ORGS_COMMENTS_UNLOADED:
            return {};
        default:
            return state;
    }
};
