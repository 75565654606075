import {
  SET_GOAL_PAGE,
  APPLY_TAG_FILTER,
  GOAL_LOAD_MORE,
  FETCH_GOALS_START,
  FETCH_GOALS_FINISHED,
  PROFILE_PAGE_GOALS_UNLOADED,
  MYSTUFF_GOALS_TAB_UNLOADED
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_GOAL_PAGE:
      return {
        ...state,
        goals: action.payload,
        goalsCount: action.payload.articlesCount,
        currentPage: action.page
      };
    case APPLY_TAG_FILTER:
      return {
        ...state,
        pager: action.pager,
        goals: action.payload,
        goalsCount: action.payload.articlesCount,
        tab: null,
        tag: action.tag,
        currentPage: 0
      };
    case GOAL_LOAD_MORE:
      let newGoals = Array.isArray(state.goals) ? state.goals.slice() : [];
      return {
        ...state,
        goals: newGoals.concat(action.payload),
        hasMore: !!action.payload?.length,
        loadMorePage: (state.loadMorePage || 0) + 1
      };
    case FETCH_GOALS_START:
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case FETCH_GOALS_FINISHED:
      return {
        ...state,
        goals: !action.payload.error ? action.payload : null,
        fetching: false,
        fetched: true,
        currentPage: 0
      };
    case PROFILE_PAGE_GOALS_UNLOADED:
    case MYSTUFF_GOALS_TAB_UNLOADED:
      return {};
    default:
      return state;
  }
};
