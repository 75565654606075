import {
    VOTES_LOADING,
    VOTES_LOADED,
    VOTE_CREATED_SUCCESSFULLY,
    AVERAGES_VOTES_LOADING,
    AVERAGES_VOTES_LOADED,
    PARTIES_VOTE_CREATED_SUCCESSFULLY,
    PARTIES_VOTES_LOADED, PARTIES_VOTES_LOADING
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case VOTES_LOADING:
      return {};
    case VOTES_LOADED:
      return {
        ...state,
        votes: action.payload
      };
    case VOTE_CREATED_SUCCESSFULLY:
      return {
        ...state,
        vote: action.payload,
        voteError: action.payload && action.payload.response && action.payload.response.body && action.payload.response.body.responseStatus && action.payload.response.body.responseStatus.message
      }
    case AVERAGES_VOTES_LOADING:
      return {};
    case AVERAGES_VOTES_LOADED:
      return {
        ...state,
        averagesVotes: action.payload
      };
    case PARTIES_VOTES_LOADING:
      return {};
    case PARTIES_VOTES_LOADED:
      return {
          ...state,
          partiesVotes: action.payload
      };
    case PARTIES_VOTE_CREATED_SUCCESSFULLY:
      return {
         ...state,
         partiesVote: action.payload,
         partiesVotesError: action.payload && action.payload.response && action.payload.response.body && action.payload.response.body.responseStatus && action.payload.response.body.responseStatus.message
      };
    default:
      return state;
  }
};