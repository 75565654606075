import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
import { connect } from 'react-redux';
import { onClickLogout, noLocation, onRedirect } from '../../actions/main';

const mapStateToProps = state => ({
  appLoaded: state.common.appLoaded,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo,
  userLoaded: state.common.userLoaded,
  settingsLoaded: state.common.settingsLoaded,
  appLoading: state.common.appLoading,
  isAuthorized: state.auth.isAuthorized
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: dispatch(onClickLogout),
  noLocation: dispatch(noLocation),
  onRedirect: dispatch(onRedirect)
});

class App extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      this.props.history.replace(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  render() {
    if (this.props.appLoaded) {
      return (
        <div>
          <Header
            {...this.props}
            appName={this.props.appName}
            onClickLogout={this.props.onClickLogout}
            currentUser={this.props.currentUser}
            user={this.props.user} />
          {this.props.children}
        </div>
      );
    }
    return (
      <div>
        <Header
          {...this.props}
          appName={this.props.appName}
          currentUser={this.props.currentUser} />
        {this.props.children}
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]).isRequired,
  onRedirect: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  noLocation: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
