import {
  COMMENT_CREATED,
  COMMENTS_LOADED,
  COMMENTS_UNLOADED,
    TOPIC_STANCE_COMMENTS_UNLOADED,
    TOPIC_STANCE_COMMENT_CREATED,
    TOPIC_STANCE_COMMENTS_LOADED
} from '../constants/actionTypes';

export default (state = { comments: {} }, action) => {
  switch (action.type) {
    case COMMENT_CREATED:
      return {
        ...state,
        comment: action.payload
      };
      case TOPIC_STANCE_COMMENT_CREATED:
      return {
        ...state,
          topicStanceComment: action.payload
      };
    case COMMENTS_LOADED:
      return {
        ...state,
        comments: action.payload
      };
      case TOPIC_STANCE_COMMENTS_LOADED:
      return {
        ...state,
          topicStanceComments: action.payload
      };
      case COMMENTS_UNLOADED:
      return {
          ...state,
          comments:{}
      };
      case TOPIC_STANCE_COMMENTS_UNLOADED:
          return {
              ...state,
              topicStanceComment:{}
          };
    default:
      return state;
  }
};
