import {
    TOPIC_EDITOR_PAGE_LOADED,
    TOPIC_EDITOR_PAGE_UNLOADED, TOPIC_UPDATE_FIELD_EDITOR, TOPIC_STANCE_SUBMITTED, ADD_TAG, REMOVE_TAG
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case TOPIC_EDITOR_PAGE_LOADED:
            return {
                ...state,
                topicStance:action.payload,
                topicStanceSlug: action.payload ? action.payload.slug : '',
                topicLanguage: action.payload ? action.payload.topicLanguage : '',
                topicBody: action.payload ? action.payload.body : '',
                id: action.payload ? action.payload.id: '',
                author: action.payload ? action.payload.author: '',
                created: action.payload ? action.payload.created: '',
                replyMyPostsAndComments: action.payload ? action.payload.replyMyTopicStanceAndComments : '',
                viewMyPostsAndComments: action.payload ? action.payload.replyMyTopicStanceAndCommentsDefault : '',
                tagList: action.payload ? action.payload?.tags : [],
            };
        case TOPIC_STANCE_SUBMITTED:
            return {
                ...state,
                inProgress: null,
                errors: action.error ? action.payload?.responseStatus?.errors : null,
                errorMessage:action.error ?  action.payload?.responseStatus?.message || '' : '',
                topicStanceSlug: action.error ? state.topicStanceSlug : '',
                topicLanguage: action.error ? state.topicLanguage : '',
                topicBody: action.error ? state.topicBody : '',
                id: action.error ? state.id: '',
                tagList: action.error ? state.tagList : [],
                tagInput: action.error ? state.tagInput :'',
                author: action.error ? state.author: '',
                created: action.error ? state.created: '',
            };
        case ADD_TAG:
            return {
                ...state,
                tagList: (state.tagList || []).concat([state.tagInput]),
                tagInput: ''
            };
        case REMOVE_TAG:
            return {
                ...state,
                tagList: state.tagList.filter(tag => tag !== action.tag)
            };
        case TOPIC_EDITOR_PAGE_UNLOADED:
            return {};
        case TOPIC_UPDATE_FIELD_EDITOR:
            return { ...state, [action.key]: action.value };
    }

    return state;
};
