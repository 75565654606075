import {
  GOALS_COMMENTS_LIST_LOADED,
  GOALS_COMMENTS_LIST_UNLOADED,
  GOALS_ADD_COMMENT_TO_COMMENTS_LIST
} from '../constants/actionTypes';

export default (state = { list: [] }, action) => {
  switch (action.type) {
    case GOALS_COMMENTS_LIST_LOADED:
      return {
        ...state,
        list: action.payload
      };
    case GOALS_ADD_COMMENT_TO_COMMENTS_LIST:
      return {
        ...state,
        list: action.payload
      }
    case GOALS_COMMENTS_LIST_UNLOADED:
      return {};
    default:
      return state;
  }
};
