import {
  BLOG_LOAD_MORE,
  BLOG_PAGE_LOADED
} from '../constants/actionTypes';
const initialState = {
  Blogs: [],
  loadMorePage: 0,
  hasMore: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG_LOAD_MORE:
      let newBlogs = Array.isArray(state.Blogs) ? state.Blogs.slice() : [];
      return {
        ...state,
        Blogs: newBlogs.concat(action.payload),
        hasMore: !!action?.payload?.length,
        loadMorePage: (state.loadMorePage || 0) + 1
      };
    case BLOG_PAGE_LOADED:
      return {
        ...state,
        Blogs: !action.payload.error ? action.payload : null,
        loadMorePage: 1,
        hasMore: action?.payload?.length >= 10
      };
    default:
      return state;
  }
};
