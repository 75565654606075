import React, {useEffect, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getLabel } from '../../language'
import "../Settings/Setting.css"
import {message} from "antd";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
message.config({top:50})

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    anonymousMode: state.user.anonymousMode
});

const AnonymousModeModel = props => {

    return (
        <Modal className={`privacy-header ${props.anonymousMode ? "dark" : ""}`} show={props.anonymousModal} onHide={props.anonymousToggle}>
            <Modal.Header>
                <Modal.Title>
                    {getLabel(280)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <legend className={`${props.anonymousMode ? "cw" : ""}`}>{props.anonymousMode ? getLabel(283) : getLabel(282)}</legend>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onChange} className={`m-t-30 btn-primary${props.anonymousMode ? "-dark" : " custom-dp"}`}>{getLabel(281)}</Button>
            </Modal.Footer>
        </Modal>
    )

};
export default withRouter(connect(mapStateToProps)(AnonymousModeModel));

