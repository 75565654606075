import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import agent from '../../agent';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { onLoad, onRedirect } from '../../actions/main';

const ROLE_GUEST = 'guest';
const ROLE_USER = 'user';

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  onLoad: dispatch(onLoad)
});

export default function (ComposedComponent, role, UnAuth, fromRoot) {
  class Authorizer extends React.Component {
    constructor(props) {
      super(props)
    }

    getComponentByRole() {
      const token = window.localStorage.getItem('bearerToken');
      if (fromRoot) {
          if (this.props.auth.isAuthorized && token) {
              return <ComposedComponent {...this.props} />
          } else {
              if(!this.props.auth.isAuthorized && !token) {
                 return <UnAuth {...this.props} />
              }
          }
      }
      switch (role) {
        case ROLE_USER : {
          if (this.props.auth.isAuthorized && token) {
            return <ComposedComponent {...this.props} />
          }
        }
        case ROLE_GUEST: {
          return <ComposedComponent {...this.props} />
        }
        default:
          return null;
      }
    }

    componentWillMount() {
      const token = window.localStorage.getItem('bearerToken');
      agent.setToken(token);

      switch (role) {
        case ROLE_USER : {
          if (!token && !this.props.location.pathname.includes('activation')) {
            this.props.history.push("/");
          }
        }
      }

      if (!this.props.auth.isAuthorized && token) {
        this.props.onLoad(token);
      }
    }

    componentDidMount() {
      const token = window.localStorage.getItem('bearerToken');
      if(!token) {
          if(this.props.history.location.pathname === '/') {
              document.getElementById("navbar").style.display = "none";
          } else {
              document.getElementById("navbar").style.display = "block";
          }
          let element = document.getElementById("content");
          element && element.classList && element.classList.remove("container");
          let element1 = document.getElementById("root");
          element1 && element1.classList && element1.classList.add("full");
          let element2 = document.getElementById("navbar");
          element2 && element1.classList && element2.classList.add("full");
      } else {
          let element = document.getElementById("content");
          element && element.classList && element.classList.add("container");
          let element1 = document.getElementById("root");
          element1 && element1.classList && element1.classList.remove("full");
          let element2 = document.getElementById("navbar");
          element2 && element2.classList && element2.classList.remove("full");
      }
    }

    render() {
      return this.getComponentByRole();
    }
  }

  Authorizer.contextTypes = {
    router: PropTypes.object.isRequired
  };

  Authorizer.defaultProps = {
    children: null
  };

  Authorizer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]),
    onLoad: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location,
    history: ReactRouterPropTypes.history
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorizer));
}

export const authRoles = {
  guest: ROLE_GUEST,
  user: ROLE_USER
};
