import { FETCH_USER_AVATAR_START, FETCH_USER_AVATAR_FINISHED, UPLOAD_USER_AVATAR_START, UPLOAD_USER_AVATAR_FINISHED, ACTIVATION_START, ACTIVATION_FINISHED, UPDATE_CURRENT_USER_START, UPDATE_CURRENT_USER_FINISHED, FETCH_CURRENT_USER_FINISHED, FETCH_CURRENT_USER_START,SET_ANONYMOUS_MODE } from '../constants/actionTypes';
import { APP_CONFIG } from "../appconfig";
const initialState = {
    anonymousMode:false,
    avatar: null,
    fetched: false,
    fetching: false,
    error: null,
    uploaded: false,
    uploading: false,
    updating: false,
    updated: false,
    activation: {
        executing: false,
        executed: false,
        success: null,
        error: null
    }
};
const defaultAvatar = APP_CONFIG.defaultAvatar;

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_AVATAR_START:
            return {
                ...state,
                fetching: true
            }
        case FETCH_USER_AVATAR_FINISHED:
            return {
                ...state,
                avatar: action.payload.error || action.payload.status >= 400 ? defaultAvatar : action.payload,
                isAvatar: !(action.payload.error || action.payload.status >= 400),
                fetching: false,
                fetched: action.payload.error ? false : true,
                error: action.payload.error ? action.payload.error : null,
            }
        case UPLOAD_USER_AVATAR_START:
            return {
                ...state,
                uploading: true
            };
        case UPLOAD_USER_AVATAR_FINISHED:
            return {
                ...state,
                uploading: false,
                uploaded: action.payload.error ? false : true,
                error: action.payload.error ? action.payload.error : null
            };
        case ACTIVATION_START:
            return {
                ...state,
                activation: {
                    executing: true,
                    executed: false
                }
            };
        case ACTIVATION_FINISHED:
            return {
                ...state,
                activation: {
                    executing: false,
                    executed: true,
                    error: action.payload.error,
                    success: action.payload.isSuccess
                }
            };

        case UPDATE_CURRENT_USER_START:
            return {
                ...state,
                fetching: true,
                updated: false
            }
        case UPDATE_CURRENT_USER_FINISHED:
            return {
                ...state,
                updating: false,
                updated: action.error ? false : true
            }
        case FETCH_CURRENT_USER_START:
            return {
                ...state,
                fetched: false,
                fetching: true,
            }
        case FETCH_CURRENT_USER_FINISHED:
            return {
                ...state,
                fetched: action.payload.error ? false : true,
                fetching: false,
                user: action.payload
            }
        case SET_ANONYMOUS_MODE:
            return {
                ...state,
                anonymousMode:action.payload
            }
        default:
            return state;
    }

    return state;
};
