import {
  MYSTUFF_FOLLOWERS_TAB_LOADED,
  MYSTUFF_FOLLOWERS_TAB_UNLOADED,
  FETCH_FOLLOWERS_STARTED,
  FETCH_FOLLOWERS_FINISHED
} from '../constants/actionTypes';

export default (state = {persons: {}}, action) => {
  switch (action.type) {
    case MYSTUFF_FOLLOWERS_TAB_LOADED:
      return {
        ...state,
        followees: action.payload
      };
    case MYSTUFF_FOLLOWERS_TAB_UNLOADED:
      return {};
    case FETCH_FOLLOWERS_STARTED:
      return {};
    case FETCH_FOLLOWERS_FINISHED:
      return {
        ...state,
        persons: action.payload
      };
    default:
      return state;
  }
};
