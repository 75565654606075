import {
    FETCH_AUDIENCES_LOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_AUDIENCES_LOADED:
            return {
                ...state,
                audiencesList: Array.isArray(action.payload) ? action.payload : []
            };
        default:
            return state;
    }
};
