import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/footer.css';
import { getLabel } from '../../language'
import { connect } from "react-redux";

class Footer extends React.Component {

    selectLan = (e) => {
        localStorage.setItem('language', e.target.value)
        window.location.reload()
    }

    render() {
        return (
            <div className="pt-75">
            <footer className="footer text-center">
                <div>
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="nav justify-content-center">
                                <li className="nav-item desktop-menu">
                                    <Link to="/about" className="nav-link">
                                        {getLabel(15)}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link">
                                        {getLabel(16)}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/privacy" className="nav-link">
                                        {getLabel(17)}
                                    </Link>
                                </li>

                                <li className="nav-item desktop-menu">
                                    <Link to="/terms" className="nav-link">
                                        {getLabel(250)}
                                    </Link>
                                </li>

                                {
                                    this.props.currentUser && this.props.currentUser.id &&
                                    <li className="nav-item">
                                        <Link to="/founding-members" className="nav-link">
                                            {getLabel(240)}
                                        </Link>
                                    </li>
                                }

                                <li className="nav-item desktop-menu">
                                    <Link to="/support-us" className="nav-link">
                                        {getLabel(238)}
                                    </Link>
                                </li>

                            </ul>
                            <select onChange={this.selectLan} className="input-sm dropLanguage"
                                value={localStorage.getItem("language") || "EN"}>
                                <option value="EN">
                                    {/* <span className="flag-icon flag-icon-us" /> */}
                                    English</option>
                                <option value="NL">
                                    {/* <span className="flag-icon flag-icon-ne" /> */}
                                    Nederlands</option>
                            </select>
                        </div>
                    </div>
                </div>
            </footer>
            </div>
        )
    };
};

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    followees: state.followees.persons,
});

export default connect(mapStateToProps, null)(Footer);
