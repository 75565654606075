import {
    TOPIC_LOAD_MORE,
    TOPIC_DELETE,
    MYSTUFF_TOPIC_STANCE_TAB_UNLOADED, FETCH_TOPIC_STANCES_START, FETCH_TOPIC_STANCES_FINISHED
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case TOPIC_LOAD_MORE:
            let newTopicStances = Array.isArray(state.topicStances) ? state.topicStances.slice() : [];
            return {
                ...state,
                topicStances: newTopicStances.concat(action.payload),
                hasMore: (action?.payload?.length > 9),
                loadMorePage: (state.loadMorePage || 0) + 1
            };
        case TOPIC_DELETE:
            return {
                ...state,
                topicStances: [],
                hasMore: true,
                loadMorePage:0
            }
        case FETCH_TOPIC_STANCES_START:
            return {
                ...state,
                fetching: true,
                fetched: false
            };
        case FETCH_TOPIC_STANCES_FINISHED:
            return {
                ...state,
                topicStances: !action.payload.error ? action.payload : null,
                fetching: false,
                fetched: true,
                currentPage: 0
            };
        case MYSTUFF_TOPIC_STANCE_TAB_UNLOADED:
            return {};
        default:
            return state;
    }
};
