import {
  POLLY_LIST_PAGE_LOADED,
  POLLY_LIST_PAGE_UNLOADED,
  POLLY_LIST_SEARCH,
  POLLY_LIST_SIDEBAR_UNLOADED,
  POLLY_LIST_SIDEBAR_LOADED,
  POLLY_FOLLOW,
  POLLY_UN_FOLLOW,
  POLLY_LOAD_MORE,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case POLLY_LIST_SIDEBAR_LOADED:
    case POLLY_LIST_PAGE_LOADED:
    case POLLY_LIST_SEARCH:
      return {
        ...state,
        pollies: action.payload,
        currentPage: 0,
        hasMore: true,
        loadMorePage: 1
      };
    case POLLY_LIST_SIDEBAR_UNLOADED:
    case POLLY_LIST_PAGE_UNLOADED:
      return {};
    case POLLY_FOLLOW:
      let pollies = state.pollies

      if (pollies && pollies.length > 0) {
        pollies = pollies.map(i => {
          if (i.personID === action.payload.personID) {
            i.followLevel = action.payload.followLevel
          }
          return i
        })
        return {
          ...state,
          pollies: pollies
        };
      }
      return state;
    case POLLY_UN_FOLLOW:
      let pollies2 = state.pollies

      if (pollies2 && pollies2.length > 0) {
        pollies2 = pollies2.map(i => {
          if (i.personID === action.payload.personID) {
            i.followLevel = action.payload.followLevel
          }
          return i
        })
        return {
          ...state,
          pollies: pollies2
        };
      }
      return state
    case POLLY_LOAD_MORE:
      let newPolly = state.pollies && state.pollies.slice();
      return {
        ...state,
        pollies: state.pollies && newPolly.concat(action.payload),
        hasMore: action.payload && !!action.payload.length,
        loadMorePage: state.loadMorePage + 1
      }
    default:
      return state;
  }
};
