import {
  PROFILE_FAVORITES_PAGE_LOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED
} from '../constants/actionTypes';

const initialState = {
  pageLoaded: false
}

export default (state = {}, action) => {
  switch (action.type) {
    case PROFILE_FAVORITES_PAGE_LOADED:
      return {
        pageLoaded: true
      };
    case PROFILE_FAVORITES_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
