import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { APP_CONFIG } from "./appconfig";
const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = APP_CONFIG.apiPath;
const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const lang = localStorage.getItem('language') || 'EN';
const tokenPlugin = req => {
    if (token) {
        req.set('Authorization', `Bearer ${token}`);
    }
}

const baseHeaderPlugin = req => {
    req.set('Content-Type', `application/json`);
}

const postImageUploadPlugin = req => {
    if (token) {
        req.set('Authorization', `Bearer ${token}`);
    }
    req.set('Accept', 'application/json, text/plain, */*');
}

const requests = {
    del: url => superagent
        .del(`${API_ROOT}${url}`)
        .use(tokenPlugin)
        .use(baseHeaderPlugin)
        .then(responseBody),
    get: url => superagent
        .get(`${API_ROOT}${url}`)
        .use(tokenPlugin)
        .use(baseHeaderPlugin)
        .then(responseBody),
    put: (url, body) => superagent
        .put(`${API_ROOT}${url}`, body)
        .use(tokenPlugin)
        .use(baseHeaderPlugin)
        .then(responseBody),
    post: (url, body) => superagent
        .post(`${API_ROOT}${url}`, body)
        .use(tokenPlugin)
        .use(baseHeaderPlugin)
        .then(responseBody)
};

const Auth = {
    current: () => requests.get('/user'),
    login: (userName, password, rememberMe) => requests.post('/auth/credentials', {
        userName: userName,
        password: password,
        rememberMe: rememberMe
    }),
    facebookLogin: (accessToken) => requests.post('/auth/facebook', {
        accessToken: accessToken,
    }),
    twitterLogin: (accessToken) => requests.post('/auth/twitter',
        accessToken,
    ),
    getTwitterAccessToken: (oauthToken, oauthVerifier) => requests.post('/auth/twitter/token', {
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier
    }),
    getAuthToken: (data) => requests.post('/auth/twitter/reverse', {
        oauth_callback: data,
    }),
    twitterSignup: (/*userName,*/ email, password, twitterAccess) => requests.post('/auth/twitter', {
        ...twitterAccess,
        // overridingUserName: userName,
        overridingPassword: password,
        overridingEmail: email
    }),
    facebookSignup: (/*userName,*/ email, password, facebookAccess) => requests.post('/auth/facebook', {
        accessToken: facebookAccess,
        // overridingUserName: userName,
        overridingPassword: password,
        overridingEmail: email
    }),
    register: ( /*userName, firstName, lastName, displayName,*/ email, password, gRecaptchaResponse) => requests.post('/register', {
        /*userName: userName,
        firstName: firstName,
     lastName: lastName,
     displayName: displayName,*/
        email: email,
        password: password,
        useTokenCookie: true,
        'g-recaptcha-response': gRecaptchaResponse
    }),
    save: (name,formData) =>superagent
        .put(`${API_ROOT}/user/${name}?format=json`)
        .use(tokenPlugin)
        .send(formData),
    updateEmailAndPassword: (username,data) => requests.put(`/user/updateEmailAndPassword/${username}?format=json`, data),
    getResetLink: email => requests.post(`/password-reset`, { email }),
    resetPassword: (data, token) => requests.post(`/password-reset/${token}`, data),
    isTokenValid: (token) => requests.get(`/password-reset/${token}`),
    sessionToToken: () => requests.post('/session-to-token', { PreserveSession: true }),
    logout: () => requests.post('/auth/logout'),
    getVotesHome: () => requests.get('/pollies/last-votes'),
};

const User = {
    activate: activationKey => requests.get(`/activation/${activationKey}`),
    userDetail: (UserName) => requests.get(`/adminutils/user/${UserName}`),
    deleteAvatar: () => requests.del(`/user/picture`),
    uploadAvatar: (userName, formData) => superagent
        .post(`${API_ROOT}/user/${userName}/picture`)
        .use(tokenPlugin)
        .send(formData),
    fetchUserAvatar: (userName) => superagent
        .get(`${API_ROOT}/user/${userName}/picture`)
        .responseType('blob')
        .use(tokenPlugin),
    friends: (userId) => requests.get(`/friends?userId=${userId}`),
    members: (page,level,order) => requests.get(`/users/memberslist?${limit(10, page)}&Sorting=${level}&Order=${order}&format=json`),
    remove: () => requests.del(`/user/self`),
    sendMessage: (data) => requests.post("/friends/invite", {
        emails: data.emails,
        body: data.body
    }),
    banned: (data) => requests.post("/ban/user",
       data
    ),
}

const UserLocations = {
    get: () => requests.get("/voter-locations"),
    save: (location) => requests.post("/voter-locations", {
        placeId: location.place_id,
        street: location.route,
        streetNumber: location.street_number,
        country: location.country,
        province: location.administrative_area_level_1,
        county: location.administrative_area_level_2,
        city: location.locality,
        postcode: location.postal_code,
        longitude: location.lng,
        latitude: location.lat
    }),
    delete: (id) => requests.del(`/voter-locations/${id}`),
    put: (id) => requests.put(`/voter-locations/${id}/set-default`),
};

const Tags = {
    getAll: () => requests.get('/tags'),
    getByName: (term) => requests.get(`/tags?term=${term}&take=100&skip=0&language=${lang}`)
};

const limit = (count, p) => `take=${count}&skip=${p
    ? p * count
    : 0}`;
const omitSlug = post => Object.assign({}, post, { slug: undefined });

const Posts = {
    all: page => requests.get(`/posts?${limit(10, page)}`),
    byAuthor: (author, page) => requests.get(`/posts?author=${encode(author)}&${limit(10, page)}`),
    byTag: (tag, page) => requests.get(`/posts?tag=${encode(tag)}&${limit(10, page)}`),
    del: id => requests.del(`/posts/${id}`),
    favorite: slug => requests.post(`/posts/${slug}/favorite`),
    favoritedBy: (author, page) => requests.get(`/posts?favorited=${encode(author)}&${limit(10, page)}`),
    feed: page => requests.get(`/posts/feed?${limit(10, page)}&language=${lang}`) ,
    get: slug =>  requests.get(`/posts/${slug}?language=${lang}`),
    unfavorite: slug => requests.del(`/posts/${slug}/favorite`),
    update: post => requests.put(`/posts/${post.id}`, { ...post }),
    getCurrentPost: post => requests.get(`/posts/${post}?language=${lang}`),
    postImageUpload: (formData) => superagent
        .post(`${API_ROOT}/posts/upload`)
        .use(postImageUploadPlugin)
        .send(formData),
    getPostImage: (ImageId) => superagent
        .get(`${API_ROOT}/posts/download/${ImageId}`)
        .responseType('blob')
        .use(tokenPlugin),
    create: post => requests.post('/posts', {
        ...post
    }),
    getPostsByEntityID: ({electionID,typePage}) => requests.get(`/posts?take=10&skip=0&language=${lang}&type=${typePage}&entityID=${electionID}`)
    //getElection: (locationId) => requests.get(`/elections/upcomingnearme?voterLocationID=${locationId}&${limit(10, 0)}`),
};

const InterViews = {
    getInterViews: (url) => requests.get(`/interviews/${url}`)
}

const Elections = {
    getElection: (locationId) => requests.get(`/elections/upcomingnearme?voterLocationID=${locationId}&${limit(30, 0)}`),
    getCandidatesList: (electionId) => requests.get(`/candidates/election/${electionId}/?includeVotes=true&take=30&skip=0`),
    // getCandidatesMyVoteList: (electionId) => requests.get(`/candidates/election/${electionId}/votes/my`),
    getCandidatesAllVoteDetails: (electionId) => requests.get(`/candidates/election/${electionId}/votes`),
    // getCandidatesAveragesVote: (electionId) => requests.get(`/candidates/election/${electionId}/votes/averages`),
    getCandidatesDetails: (electionId, personID, electionType) => requests.get(`/candidates/election/${electionId}/votes?${electionType}=${personID}`),
    postCandidatesVote: (vote, electionId) => {
        return requests.post(`/candidates/election/${electionId}/votes`, {
            personID: vote.personID,
            partyId: vote.partyId,
            electionID: vote.electionID,
            vision: vote.vision,
            ability: vote.ability,
            comment: vote.comment,
            isUndecided: vote.isUndecided,
            viewMyVotes: vote.viewMyVotes,
            viewMyVotesDefault: vote.viewMyVotesDefault,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    },
    getComments: (electionID) => requests.get(`/elections/${electionID}/comments?${limit(10, 0)}`),
    addComment: (electionID, body) => new Promise(resolve => resolve(requests.post(`/elections/${electionID}/comments`, body))),
    getRelatedElections: electionID => requests.get(`/elections/${electionID}/related?${limit(10, 0)}`),
}

const Audiences = {
    getAudiences: () => requests.get(`/audiences?${limit(10, 0)}`),
    addAudience: (data) => new Promise(resolve => resolve(requests.post(`/audiences`, data))),
    updateAudience: (AudienceID, data) => new Promise(resolve => resolve(requests.put(`/audiences/${AudienceID}`, data))),
    removeAudience: (AudienceID, data) => new Promise(resolve => resolve(requests.del(`/audiences/${AudienceID}`, data))),
    getAudienceMember: (AudienceID) => requests.get(`/audiences/${AudienceID}/members?${limit(10, 0)}`),
    areMembers: (audienceId, userIds) => requests.get(`/audiences/${audienceId}/aremembers?userIds=${userIds}`),
    addMemberInAudience: (AudienceID, memberID) => requests.post(`/audiences/${AudienceID}/members/${memberID}`),
    removeMemberInAudience: (AudienceID, memberID) => requests.del(`/audiences/${AudienceID}/members/${memberID}`),
}

const Pollies = {
    all: page => new Promise(resolve => resolve(mockPollies)),
    //get: id =>
    //  new Promise(resolve => resolve(mockPollies.find(item => item.PersonID ==
    // id))),
    getPollies: id =>  requests.get(`/pollies/${id}`),
    getByURLSlug: URLSlug =>
        requests.get(`/pages/${URLSlug}?language=${lang}`),
    getPostByTagId : (tagId) => requests.get(`/postsbytag/${tagId}?take=10&skip=0&format=json`),
    getPostByTopicOrLocation : (term,type) => requests.get(`/tags?term=${term}&type=${type}&take=10&skip=0&exactMatch=true`),
    getTopicStanceByAuthorIdAndTopicID: (id) => requests.get(`/topicstanceByAuthorIdAndTopicID?topicId=${id}`),
    getRelated: () => new Promise(resolve => resolve([
        mockPollies[Math.floor(Math.random() * mockPollies.length)],
        mockPollies[Math.floor(Math.random() * mockPollies.length)],
        mockPollies[Math.floor(Math.random() * mockPollies.length)]
    ])),
    getLastVote: (personId) => requests.get(`/pollies/${personId}/last-vote`),
    getPolliesWorkForMe: (voterlocation, page) =>
        requests.get(`/pollies/workforme?${limit(10, page)}${voterlocation || ''}&include=votes`),
    searchPollies: (search, page) =>
        requests.get(`/pollies?term=${search}`),
    createVote: (vote) => {
        return requests.post(`/pollies/daily-votes`, {
            personId: vote.personId,
            ability: vote.ability,
            vision: vote.vision,
            viewMyVotes: vote.viewMyVotes,
            viewMyVotesDefault: vote.viewMyVotesDefault,
            comment: vote.polliesComment,
            vote: vote.vote,
            isFinal: !!vote.isFinal,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false

        })
    },
    getPolliesVotes: polliesIDs =>
        requests.get(`/pollies/daily-votes?PersonIDs=${polliesIDs}`),
    createPartiesVote: (vote) => {
        return requests.post(`/parties/daily-votes`, {
            partyId: vote.personId,
            ability: vote.ability,
            vision: vote.vision,
            viewMyVotes: vote.viewMyVotes,
            viewMyVotesDefault: vote.viewMyVotesDefault,
            comment: vote.polliesComment,
            vote: vote.vote,
            isFinal: !!vote.isFinal,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    },
    addPartyComments: (partyId, body) => new Promise(resolve => resolve(requests.post(`/parties/${partyId}/comments `, body))),
    getPartyComments: (partyId) => requests.get(`/parties/${partyId}/comments`),
    getPartyVotes: polliesIDs =>
        requests.get(`/parties/daily-votes?PartyIds=${polliesIDs}`),
    getAveragesPartyVotes: (personId) => requests.get(`/parties/${personId}/daily-votes/averages`),
    getRelatedParty: PersonID =>
        requests.get(`/pollies?partyId=${PersonID}`),
    getAveragesVotes: (personId) => requests.get(`/pollies/${personId}/daily-votes/averages`),
    getComments: (personId) => requests.get(`/pollies/${personId}/comments`),
    getRelatedPollicies: PersonID =>
        requests.get(`/pollies/${PersonID}/related?take=10&skip=0&include=votes`),
    addComment: (personId, body) => new Promise(resolve => resolve(requests.post(`/pollies/${personId}/comments `, body))),
    getCommentsUserInfoList: userIds => requests.get(`/users?userIds=${userIds}`),
    searchForTag: (serchText, name) => requests.get(`/tags?term=${serchText}${name === "party" ? "&type=Party" : "&type=Polly"}&take=10&skip=0`),
    newsSearchForTag: (searchText, name, electionID) => requests.get(`/tags?term=${searchText}${name === "party" ? "&type=Party" : `&type=${name}`}&take=10&skip=0&entityID=${electionID}`),
    getNewsList: (tagIDs) => requests.get(`/posts?take=10&skip=0&tags=${tagIDs}`),
    deletePollyVote: (id) => requests.del(`/pollies/daily-votes/${id}`),
    deletePartyVote: (id) => requests.del(`/parties/daily-votes/${id}`),
    getPollyVotes: (username, page, isFinal) => requests.get(`/pollies/daily-votes/${username}${isFinal ? '/final' : ''}?take=10&skip=${page * 10}`)
};

const Comments = {
    create: (postId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/posts/${postId}/comments`, {
            postId: postId,
            body: body,
            viewMyPostsAndComments: viewMyPostsAndComments,
            replyMyPostsAndComments: replyMyPostsAndComments,
            viewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            replyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    ),
    createReply: (postId, commentId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/posts/comments/${commentId}/replies`, {
            postId: postId,
            body: body,
            viewMyPostsAndComments: viewMyPostsAndComments,
            replyMyPostsAndComments: replyMyPostsAndComments,
            viewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            replyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    ),
    topicStanceCreateReply: (topicStanceId, commentId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/topicStance/comments/${commentId}/replies`, {
            postId: topicStanceId,
            body: body,
            viewMyTopicStancesAndComments: viewMyPostsAndComments,
            replyMyTopicStancesAndComments: replyMyPostsAndComments,
            viewMyTopicStancesAndCommentsDefault: viewMyPostsAndCommentsDefault,
            replyMyTopicStancesAndCommentsDefault: replyMyPostsAndCommentsDefault,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    ),
    delete: (slug, commentId) => requests.del(`/articles/${slug}/comments/${commentId}`),
    forPost: postId => requests.get(`/posts/${postId}/comments`),
    forTopicStance: topicStanceId => requests.get(`/topicStance/${topicStanceId}/comments`),
    getcommentsList: postIds => requests.get(`/posts/comments?postIds=${postIds}&take=10&skip=0`),
    getTopicStancecommentsList: topicStancesIds => requests.get(`/topicStance/comments?topicStanceIds=${topicStancesIds}&take=10&skip=0`),
    topicStanceCreate: (topicStanceId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/topicStance/${topicStanceId}/comments`, {
            body: body,
            viewMyTopicStancesAndComments: viewMyPostsAndComments,
            replyMyTopicStancesAndComments: replyMyPostsAndComments,
            viewMyTopicStancesAndCommentsDefault: viewMyPostsAndCommentsDefault,
            replyMyTopicStancesAndCommentsDefault: replyMyPostsAndCommentsDefault,
            Anonymous: JSON.parse(localStorage.getItem("AnonymousMode")) || false
        })
    ),
};

const Mentions = {
    get: term => {
        let path = `/mention`;
        if (term) {
            path = `/mention?term=${term}`;
        }
        return requests.get(path);
    }
}

const Profile = {
    all: page =>
        requests.get(`/user?${limit(10, page)}`),
    followees: (userId, page) =>
        requests.get(`/followees?userId=${userId}`),
    followers: (userId, page) =>
        requests.get(`/followers?userId=${userId}`),
    follow: (userId, followeeId, followeeType, followLevel) =>
        requests.post(`/follows`, {
            userId: userId,
            followeeId: followeeId,
            followeeType: followeeType,
            followLevel: followLevel
        }),
    followeesByUserId: (userId) =>
        requests.get(`/followees/${userId}`),
    get: username =>
        requests.get(`/user/${username}`),
    unfollow: (followeeId, followeeType) =>
        // requests.del(`/follows/unfollow?followeeID=${followeeId}&followeeType=${followeeType}`),
        requests.del(`/follows?followeeID=${followeeId}&followeeType=${followeeType}`),
    getPoliticiansList: () =>
        new Promise(resolve => resolve([mockPollies[1], mockPollies[2], mockPollies[3]])),
};

const PreviewUrl = {
    get: site => {
        return superagent
            .post(`${API_ROOT}/imagepreview?format=json`)
            .use(postImageUploadPlugin)
            .send({Url:site})
    }
}

const votedetails = {
    getPollyVoteDetails: (urlslug, username, date) => requests.get(`/pollies/${urlslug}/daily-votes/${username}/${date}`),
    getPartyVoteDetails: (urlslug, username, date) => requests.get(`/parties/${urlslug}/daily-votes/${username}/${date}`),
}

const FoundMember = {
    getFoundMember: (page) => requests.get(`/users/memberslist?skip=${page}&take=10&onlyfounding=true`),
}

const AdminSetting = {
    setAdminSetting: (type, body) => requests.post(`/adminutils/aggregation/${type}`, body),
    getUsersList: (type, body) => requests.get(`/adminutils/users/list?Sort=desc`),
    getDailyVotesList: (type, body) => requests.get(`/adminutils/dailyvotes/list`),
    getCalculateAll: (body) => requests.post(`/adminutils/aggregation/userLevelCalcAll`,body),
    getCalculateOne: (body) => requests.post(`/adminutils/aggregation/userLevelCalc`, body),
    getDistrictAll: (body) => requests.post(`/adminutils/aggregation/RecalcVoterDistrictAll`, body),
    getDistrictOne: (body) => requests.post(`/adminutils/aggregation/RecalcVoterDistrict`, body),
    getPostActivity: () => requests.get(`/adminutils/posts?&format=json`),
    getTopicStanceActivity: () => requests.get(`/adminutils/topicstance/feed`),
    getCommentActivity: () => requests.get(`/allComments/feed?&format=json`),
    deletePost:(id,data) =>requests.put(`/post/${id}`,data),
    deleteComment:(id,data) =>requests.put(`/adminutils/post/comment/${id}?format=json`,data),
    fillLanguageTables:() =>requests.get(`/adminutils/localization/filltables`),
    topicStance:(page) =>requests.get(`/adminutils/topicstance/feed?format=json&${limit(10, page)}`),
    byAuthor: () => requests.get(`/topicstanceByAuthorIdAndTopicId`),
    byAuthorId: (id,page) => requests.get(`/topicstanceByAuthorId/${id}`),
}

const topicStance = {
    del:(id) =>requests.del(`/topicstance/${id}`),
    create:(data) => requests.post(`/topicstance`,data),
    update: (data) => requests.put(`/topicstance/${data.id}`, { ...data }),
    apporoveReject: (data) => requests.put(`/topicstance/approveReject`, { ...data }),
    get: id =>  requests.get(`/topicstanceByIdAdmin/${id}`),
    getBySlug: slug =>  requests.get(`/topicstance/${slug}`),
    getByTopicId: (id,page) =>
        requests.get(`/topicstance/feed/${id}?${limit(10, page)}&language=${lang}`),
}

const Blog = {
    getBlogs: (page) => requests.get(`/Blog?${limit(10, page)}`),
    getBlogsByUrlSlug:(url) => requests.get(`/Blog/getblogpostbyurl/${url}`)
}

const Goals = {
    all: page => requests.get(`/goals?${limit(10, page)}`),
    feedGoals: (page, locationId) => requests.get(`/goals/feed?${limit(10, page)}&VoterLocationId`),
    getGoal: (id) => requests.get(`/goals/${id}`),
    byAuthor: (author, page) => requests.get(`/goals?author=${encode(author)}&${limit(10, page)}`),
    getAdminGoals: (page) => requests.get(`/adminutils/goals/list?${limit(10, page)}`),
    getCurrentGoal: goal => requests.get(`/goals/${goal}`),
    createGoalWithFormData: (data) => requests.post(`/goals?format=json`,data),
    updateGoal: (formData, id) => requests.put(`/goals/${id}`, formData),

    // createGoalWithFormData: (formData) => superagent
    //     .post(`${API_ROOT}/goals`)
    //     .use(postImageUploadPlugin)
    //     .send(formData),
    // updateGoal: (formData, id) => superagent
    //     .put(`${API_ROOT}/goals/${id}`)
    //     .use(postImageUploadPlugin)
    //     .send(formData),
    delete: id => requests.del(`/goals/${id}`),
    goalImageUpload: (formData) => superagent
        .post(`${API_ROOT}/goals/upload`)
        .use(postImageUploadPlugin)
        .send(formData),
    deleteGoalImage: (id) => requests.del(`/goals/${id}/file?format=json`),

}

const Organizations = {
    getOrg: (id) => requests.get(`/organisations/${id}`),
    getOrgByAuthor: (page) => requests.get(`/organisations?${limit(10, page)}`),
    getOrgFeeds: (page) => requests.get(`/organisations/feed?take=1000&skip=0`),
    getOrgs: (page) => requests.get(`/organisations/feed?take=10&skip=0`),
    getAdminOrg: () => requests.get(`/adminutils/organisations/list?take=1000&skip=0`),
    getOrgById: (slug) => requests.get(`/adminutils/organisationsbyURLSlug/${slug}`,{ "URLSlug":slug}),
    createOrgWithFormData: (formData) => superagent
        .post(`${API_ROOT}/organisations`)
        .use(postImageUploadPlugin)
        .send(formData),
    updateOrg: (formData, id) => superagent
        .put(`${API_ROOT}/organisations/${id}`)
        .use(postImageUploadPlugin)
        .send(formData),
    delete: id => requests.del(`/organisations/${id}`),
    orgImageUpload: (formData) => superagent
        .post(`${API_ROOT}/organisations/upload?format=json`)
        .use(postImageUploadPlugin)
        .send(formData),
    // getOrgImage: (id) => requests.get(`/organisations/download/${id}?format=json`)
    getOrgImage: (id) => superagent
        .get(`${API_ROOT}/organisations/download/${id}?format=json`)
        .responseType('blob')
        .use(tokenPlugin),

};

const OrgsComments = {
    getOrgsCommentsList: (id) => requests.get(`/organisation/${id}/comments?Take=100&skip=0`),
    forPost: id => requests.get(`/organisation/${id}/comments?${limit(10, 0)}`),
    createComment: (id, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/organisations/${id}/comments`, {
            Id: id,
            Body: body,
            ViewMyPostsAndComments: viewMyPostsAndComments,
            ReplyMyPostsAndComments: replyMyPostsAndComments,
            ViewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            ReplyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault
        })
    ),
    createReply: (id, commentId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/organisations/comments/${id}/replies`, {
            // postId: postId,
            Body: body,
            ViewMyPostsAndComments: viewMyPostsAndComments,
            ReplyMyPostsAndComments: replyMyPostsAndComments,
            ViewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            ReplyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault
        })
    ),
    deleteComment: (id) => requests.del(`/organisations/comments/${id}`),
    getOrgsReplies : (id) => requests.get(`/organisation/comments/${id}/replies`),
    putOrgsComments : (id, commentId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => requests.put(`/organisations/${id}/comments`, {
        Body: body,
        ViewMyPostsAndComments: viewMyPostsAndComments,
        ReplyMyPostsAndComments: replyMyPostsAndComments,
        ViewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
        ReplyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault
    }),
    getSpecificComment : (id) => requests.get(`/organisation/comments/${id}`),
};

const GoalsComments = {
    create: (goalId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/goals/${goalId}/comments`, {
            Id: goalId,
            Body: body,
            ViewMyPostsAndComments: viewMyPostsAndComments,
            ReplyMyPostsAndComments: replyMyPostsAndComments,
            ViewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            ReplyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault
        })
    ),
    createReply: (goalId, commentId, body, viewMyPostsAndComments, replyMyPostsAndComments, viewMyPostsAndCommentsDefault, replyMyPostsAndCommentsDefault) => (
        requests.post(`/goals/comments/${commentId}/replies`, {
            // postId: postId,
            Body: body,
            ViewMyPostsAndComments: viewMyPostsAndComments,
            ReplyMyPostsAndComments: replyMyPostsAndComments,
            ViewMyPostsAndCommentsDefault: viewMyPostsAndCommentsDefault,
            ReplyMyPostsAndCommentsDefault: replyMyPostsAndCommentsDefault
        })
    ),
    delete: (slug, commentId) => requests.del(`/articles/${slug}/comments/${commentId}`),
    forPost: goalId => requests.get(`/goals/${goalId}/comments?${limit(10, 0)}`),
    getGoalsCommentsList: goalIds => requests.get(`/goals/comments?goalIds=${goalIds}&take=10&skip=0`)
};

const Interactions = {
    setInteraction: (body) => requests.post(`/interactions/`, body),
    getInteractionAdmin: () => requests.get(`/adminutils/interaction/list?Take=100&Skip=0`)
}

const Abuse = {
    setAbuse: payload => requests.post(`/abuse`, payload),
    getAbuseAdminId: () => requests.get(`/adminutils/abuse/list?Take=100&Skip=0`),
    getAbuseById: EntityId => requests.get(`abuse/${EntityId}`),
    approveAbuse: body => requests.post(`/approveAbuse/`, body),
    rejectAbuse: body => requests.post(`/rejectAbuse/`, body)
};

const HotTopics ={
    getHotTopics : () => requests.get(`/hottopic/${lang}`)
};

const DeleteComment = {
    deleteComment: (formData) =>superagent
        .post(`${API_ROOT}/adminutils/DeleteCommentRequest?format=json`)
        .use(tokenPlugin)
        .send(formData),
    deleteTopicStanceComment:(id)=> requests.del(`/topicStance/comments/${id}`),
}

export default {
    Posts,
    Auth,
    Audiences,
    UserLocations,
    User,
    Pollies,
    Comments,
    OrgsComments,
    Mentions,
    Profile,
    Tags,
    PreviewUrl,
    Elections,
    votedetails,
    FoundMember,
    AdminSetting,
    Goals,
    Organizations,
    GoalsComments,
    Interactions,
    Abuse,
    DeleteComment,
    topicStance,
    HotTopics,
    InterViews,
    Blog,
    setToken: _token => {
        token = _token;
    }
};

let mocVotes = [
    // { polly_id: 46, user_id: 22, datetime: new Date() - 7*24*3600000, ability: 3,
    // vision: 6 }
];

let mockPollies = [
    {
        PersonID: 45,
        Name: 'Robert Aderholt',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'AL',
        City: '',
        District: '4',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Republican Party',
        PhotoName: 'http://s019.radikal.ru/i614/1706/ff/5dc80fdeea23.jpg',
        isWatch: true,
        isWatchClosely: false
    }, {
        PersonID: 46,
        Name: 'Justin Amash',
        displayLocation: 'NY, New York, USA',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'MI',
        City: '',
        District: '3',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Republican Party',
        PhotoName: 'http://s015.radikal.ru/i331/1706/64/f7c6ff77190d.jpg',
        isWatch: false,
        isWatchClosely: true
    }, {
        PersonID: 49,
        Name: 'Lou Barletta',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'PA',
        City: '',
        District: '11',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Republican Party',
        PhotoName: 'http://s012.radikal.ru/i320/1706/3b/89b304c76a73.jpg',
        isWatch: false,
        isWatchClosely: false
    }, {
        PersonID: 50,
        Name: 'Joe Barton',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'TX',
        City: '',
        District: '6',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Republican Party',
        PhotoName: 'http://s018.radikal.ru/i519/1706/d5/ba42585244e2.jpg',
        isWatch: true,
        isWatchClosely: false
    }, {
        PersonID: 51,
        Name: 'Karen Bass',
        Gender: 1,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'CA',
        City: '',
        District: '37',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Democratic Party',
        PhotoName: 'http://s16.radikal.ru/i190/1706/ba/cc872f55b5b0.jpg',
        isWatch: true,
        isWatchClosely: false
    }, {
        PersonID: 52,
        Name: 'Xavier Becerra',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'CA',
        City: '',
        District: '34',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Democratic Party',
        PhotoName: 'http://s002.radikal.ru/i198/1706/9c/53745afc531e.jpg',
        isWatch: true,
        isWatchClosely: false
    }, {
        PersonID: 1144,
        Name: 'Jack Bergman',
        Gender: 0,
        OfficeName: 'Congress',
        Title: 'Representative',
        State: 'MI',
        City: '',
        District: '1',
        TermTitle: 'Congress 2016-2018',
        PartyName: 'Republican Party',
        PhotoName: 'http://s011.radikal.ru/i316/1706/c5/84d6357348aa.jpg',
        isWatch: true,
        isWatchClosely: false
    }
];
