import {
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  FETCH_PROFILE_START,
  FETCH_PROFILE_FINISHED, FETCH_USER_FOLLOW_FINISHED,FETCH_USER_STATUS
} from '../constants/actionTypes';

const initialState = {
  profilePageLoaded: false,
  fetching: false,
  fetched: false,
  profile: null,
  follow: {},
  profileStatus:{}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PAGE_LOADED:
      return {
        ...state,
        profilePageLoaded: true
      };
    case PROFILE_PAGE_UNLOADED:
      return {
        profilePageLoaded: false
      };
    case FETCH_PROFILE_START:
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    case FETCH_PROFILE_FINISHED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: !action.payload.error ? action.payload : null
      };
    case FETCH_USER_FOLLOW_FINISHED:
      return {
        ...state,
        follow: !action.payload.error ? action.payload : null
      }
      case FETCH_USER_STATUS:
      return {
        ...state,
        profileStatus: !action.payload.error ? action.payload : null
      }
    default:
      return state;
  }
};
