import {
  TAGS_SUGGESTIONS_LOADED,
  TAGS_SUGGESTIONS_UNLOADED,
  ADD_TAG,
  REMOVE_TAG
} from '../constants/actionTypes';

const defaultState = {
  suggestions: [],
  tags: [],
  hasError: false,
  error: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TAG:
      return {
        ...state,
        tags: action.payload
      }
    case REMOVE_TAG: 
      return {
        ...state,
        tags: action.payload
      }
    case TAGS_SUGGESTIONS_LOADED:
      return {
        ...state,
        suggestions: action.payload,
        hasError: action.payload.hasError,
        error: action.payload.error
      };
    case TAGS_SUGGESTIONS_UNLOADED:
      return {
        ...state,
        suggestions: [],
        tags: []
      }
    default:
      return state;
  }
};