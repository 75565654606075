import {
    MYSTUFF_FRIENDS_TAB_LOADED,
    MYSTUFF_FRIENDS_TAB_UNLOADED,
} from '../constants/actionTypes';

const initialState = {
    friends: [],
    isSuccess: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MYSTUFF_FRIENDS_TAB_LOADED:
            return {
                ...state,
                friends: action.payload
            };
        case MYSTUFF_FRIENDS_TAB_UNLOADED:
            return {};
        default:
            return state;
    }
};
