import {
    POLLY_PAGE_LOADED,
    POLLY_PAGE_UNLOADED,
    POLLY_VOTES_LOADED,
    POLLY_VOTES_UNLOADED,
    POLLY_COMMENTS_LOADED,
    PARTY_COMMENTS_LOADED,
    RELATED_PARTY_LOADED,
    RELATED_POLLY_LOADED,
    POLLY_COMMENTS_USER_INFO_LOAD,
    POLLY_NEWS_LOADED
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case POLLY_PAGE_LOADED:
            const local = localStorage.getItem('language');
            const language = action.payload[0]?.language;
            if(!local && language){
              localStorage.setItem('language', language);
              window.location.reload()
            }
            return {...state,
                polly: action.payload[0],
                related: action.payload[1]
            };
        case POLLY_PAGE_UNLOADED:
            return {};
        case POLLY_VOTES_LOADED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                polly: !action.payload.error ? action.payload : null
            }
        case POLLY_VOTES_UNLOADED:
            return {};
        case POLLY_COMMENTS_LOADED:
            return {
                ...state,
                commentsList: action.payload
            }
        case POLLY_NEWS_LOADED:
            return {
                ...state,
                newsList: action.payload
            }
        case POLLY_COMMENTS_USER_INFO_LOAD:
            const newUserInfo = state.userInfo || {}
            return {
                ...state,
                userInfo: {
                    ...newUserInfo,
                    ...action.payload,
                }
            }
        case RELATED_POLLY_LOADED:
            return {
                ...state,
                relatedPolliciesList: action.payload
            }
        case PARTY_COMMENTS_LOADED:
            return {
                ...state,
                partyCommentsList: action.payload
            }
        case RELATED_PARTY_LOADED:
            return {
                ...state,
                relatedPartyList: action.payload
            }
        default:
            return state;
    }
};
