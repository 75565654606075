import post from './post';
import postList from './postList';
import goalList from './goalList';
import auth from './auth';
import { combineReducers } from 'redux';
import common from './common';
import mention from './mention';
import editor from './editor';
import home from './home';
import profile from './profile';
import profileFavorites from './profileFavorites';
import pollyList from './pollyList';
import votes from './votes';
import polly from './polly';
import quickPost from './quickPost';
import user from './user';
import followees from './followees';
import followers from './followers';
import friends from './friends';
import page from './page';
import comments from './comments';
import commentsList from './commentsList';
import goalsComments from './goalsComments';
import goalsCommentsList from './goalsCommentsList';
import goal from './goal';
import tags from './tags';
import electionList from './election';
import audiencesList from './audiences';
import members from './members';
import OrgsComments from "./OrgsComments";
import OrgsCommentsList from "./OrgsCommentsList";
import topicStancesList from "./topicStancesList";
import topicEditor from "./topicEditor";
import topicPageStanceList from "./topicPageStanceList"
import reviewPage from "./review"
import blogList from './Blog';

export default combineReducers({
  post,
  postList,
  pollyList,
  votes,
  polly,
  auth,
  common,
  mention,
  editor,
  home,
  profile,
  profileFavorites,
  quickPost,
  user,
  followees,
  followers,
  friends,
  page,
  comments,
  commentsList,
  goal,
  goalsComments,
  goalsCommentsList,
  OrgsComments,
  OrgsCommentsList,
  goalList,
  tags,
  electionList,
  audiencesList,
  members,
  topicStancesList,
  topicEditor,
  topicPageStanceList,
  reviewPage,
  blogList,
});
