import {
    FETCH_ELECTION_LIST,
    FETCH_ELECTION_CANDIDATES_LIST,
    ELECTION_PAGE_LOAD,
    ELECTION_COMMENTS_LOADED,
    ELECTION_COMMENTS_USER_INFO_LOAD,
    ELECTION_NEWS_LOADED,
    RELATED_ELECTION_LOADED,
    ELECTION_COMMENTS_LOADING, GET_HOT_TOPICS
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_ELECTION_LIST:
            return {
                ...state,
                electionList: action.payload ? action.payload : []
            };
        case FETCH_ELECTION_CANDIDATES_LIST:
            return {
                ...state,
                candidatesList: action.payload ? action.payload : [],
                isCandidatesLoading: false,
            };
        case ELECTION_PAGE_LOAD:
            return {
                ...state,
                candidatesList: [],
                isCandidatesLoading: true
            };
        case ELECTION_COMMENTS_LOADING:
            return{
                ...state,
                isLoadingComment: true
            }
        case ELECTION_COMMENTS_LOADED:
            return {
                ...state,
                commentsList: action.payload || [],
                isLoadingComment: false
            }
      case ELECTION_NEWS_LOADED:
        return {
          ...state,
          ElectionNews: action.payload || [],
        }
        case ELECTION_COMMENTS_USER_INFO_LOAD:
            const newUserInfo = state.userInfo || {}
            return {
                ...state,
                userInfo: {
                    ...newUserInfo,
                    ...action.payload,
                }
            };
        case RELATED_ELECTION_LOADED:
            return {
                ...state,
                relatedElectionList: action.payload || []
            };
        case GET_HOT_TOPICS:
            return {
                ...state,
                hotTopicsList:action.payload || []
            };
        default:
            return state;
    }
};
