import {
    MEMBER_LOAD,
    MEMBER_LOAD_MORE,
    MEMBER_UNLOADED,
} from '../constants/actionTypes';

const initialState = {
    members: [],
    isSuccess: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MEMBER_LOAD:
            return {
                ...state,
                members: action.payload,
                currentPage: 0,
                hasMore: true,
                loadMorePage: 1
            };
        case MEMBER_LOAD_MORE:
            let newMembers = state.members.slice();
            return {
                ...state,
                members: newMembers.concat(action.payload),
                hasMore: !!action.payload.length,
                loadMorePage: state.loadMorePage + 1
            };
        case MEMBER_UNLOADED:
            return {};
        default:
            return state;
    }
};
