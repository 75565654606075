import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import agent from "../../agent";
import {FETCH_USER_AVATAR_FINISHED, FETCH_USER_AVATAR_START, SET_ANONYMOUS_MODE} from '../../constants/actionTypes';
import '../../css/header.css';
import defaultAvatar from '../../images/avatar.svg';
import {getLabel} from '../../language';
import {Switch} from 'antd';
import AnonymousModeModel from "../Utils/AnonymousModeModel";


const mapDispatchToProps = (dispatch, ownProps) => {
    const onMode = (checkedMode) => {
        dispatch({type: SET_ANONYMOUS_MODE, payload: checkedMode})
    }
    const fetchUser = (userName) => {
        dispatch({type: FETCH_USER_AVATAR_START});
        agent.User.fetchUserAvatar(userName).then(res => {
            var reader = new window.FileReader();
            reader.readAsDataURL(res.xhr.response);
            reader.onloadend = function () {
                dispatch({type: FETCH_USER_AVATAR_FINISHED, payload: reader.result});
            }
        }, err => {
            dispatch({type: FETCH_USER_AVATAR_FINISHED, payload: err});
        })
    }
    return {
        fetchUserAvatar: fetchUser,
        onAnonymousMode: onMode
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        anonymousMode: state.user.anonymousMode
    }
}

const LoggedOutView = props => {
    if (!props.currentUser || props.currentUser.bannedStatus === 1) {
        return (
            <div>

                <ul className={`nav navbar-nav navbar-right${props.mode?"-dark":""} desktop-menu`}>
                    <li className="login-box">
                        <Link to="/blog" className="nav-link">
                            {getLabel(37)}
                        </Link>
                    </li>
                    {/*<li className="login-box">
                        <Link to="/community" className="nav-link">
                            {getLabel(38)}
                        </Link>
                    </li>*/}
                    <li className="login-box">
                        <Link to="/register" className="nav-link">
                            {getLabel(11)}
                        </Link>
                    </li>

                    <li className="login-box">
                        <Link to="/login" onClick={()=>localStorage.removeItem("AnonymousMode")} className="nav-link">
                            {getLabel(4)}
                        </Link>
                    </li>
                </ul>
                <ul className="nav navbar-nav mobile-menu pull-right">
                    <li className="dropdown">
                        <button className="right-menu-item dropdown-toggle waves-effect" data-toggle="dropdown"
                                id="menu-icon">
                            <i className="dripicons-menu"/></button>
                        <ul className="dropdown-menu dropdown-menu-right position-absolute dropdown-menu-main dropdown-lgg user-list notify-list col-md-2 desktop-width">
                            <li className="login-box mobile-menu">
                              <Link to="/blog" className="nav-link">
                                {getLabel(37)}
                              </Link>
                            </li>
                            {/*<li className="login-box mobile-menu">
                                <Link to="/community" className="nav-link">
                                    {getLabel(38)}
                                </Link>
                            </li>*/}
                            <li className="login-box mobile-menu">
                                <Link to="/register" className="nav-link">
                                    {getLabel(11)}
                                </Link>
                            </li>

                            <li className="login-box mobile-menu">
                                <Link to="/login" onClick={()=>localStorage.removeItem("AnonymousMode")} className="nav-link">
                                    {getLabel(4)}
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
        );
    }
    return null;
};

const LoggedInView = props => {

    if (props.currentUser && props.currentUser.bannedStatus === 0) {
        const isAdmin = (props.currentUser.roles || []).includes("Admin")

        return (
            <div className={`menuholder justify-content-between  navbar-right${props.mode?"-dark":""} mobile-menuholder`}>

                <ul className="nav navbar-nav desktop-menu">
                    {/*<li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>*/}
                    {/*    <Link to="/goals">*/}
                    {/*        {getLabel(259)}*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {isAdmin && (
                        <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                            <Link to="/organisations">
                                {getLabel(270)}
                            </Link>
                        </li>
                    )}
                    <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                        <Link to="/blog" className="nav-link">
                            {getLabel(37)}
                        </Link>
                    </li>
                    {/*<li className="hidden-xs mainlink">
                        <Link to="/community" className="nav-link">
                            {getLabel(38)}
                        </Link>
                    </li>*/}
                    <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                        <Link to="/pollies">
                            {getLabel(39)}
                        </Link>
                    </li>
                    <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                        <Link to="/editor">
                            {getLabel(40)}
                        </Link>
                    </li>
                    <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                        <Link to="/members">
                            {getLabel(41)}
                        </Link>
                    </li>
                    <li className={`dropdown user-box${props.mode ? "-dark" : ""}`}>
                        <Link to={props.mode? "" :`/@${props.currentUser.userName}`}
                              className="dropdown-toggle waves-effect user-link">
                            <img onError={e => e.target.src = defaultAvatar}
                                 src={props.mode ? defaultAvatar: (props.user && props.user.avatar ? props.user.avatar : defaultAvatar)}
                                 className={`rounded-circle user-img`}  alt={props.currentUser.userName}/>
                                 <div className={`user-box-name ${props.mode ? "user-name-dark pt-7" : "pt-7"}`}>
                            <div className={`hidden-xs`}> {getLabel(47)}</div>
                            <div className={`hidden-xs`}> {props.mode ? (getLabel(284)) :(props.currentUser.displayName ? props.currentUser.displayName : props.currentUser.userName)}</div>
                                 </div>
                            {props.currentUser.username}
                        </Link>
                    </li>
                    {props?.currentUser?.user_privilege?.mayPostAnonymously &&
                    <li className={`hidden-xs mainlink${props.mode?"-dark":""}`}>
                        <div className="pt-20">
                            <Switch size="small" checked={props.mode} onClick={props.anonymousToggle}/>
                            <span className="hidden-xs fs-13 anonymous-dark"> {getLabel(280)}</span>
                        </div>
                    </li>
                    }

                </ul>
                <ul className="nav navbar-nav mobile-size">
                    <li className="dropdown user-box">
                        <div className={`dropdown ${props.dropDown && 'show open'}`}>
                            <button className="right-menu-item dropdown-toggle waves-effect" data-toggle="dropdown"
                                    onClick={props.dropDownToggel} aria-expanded={props.dropDown} id="menu-icon">
                                <i className="dripicons-menu"/></button>
                            <ul className={`dropdown-menu dropdown-menu-right dropdown-menu-main dropdown-lgg user-list notify-list col-md-2 position-absolute desktop-width ${isAdmin ? "admin-menu" : ""}`}>
                                <li className="nav-item mobile-menu">
                                    <Link to={`/@${props.currentUser.userName}`}
                                          className="dropdown-toggle waves-effect nav-link user-link">
                                        <img onError={e => e.target.src = defaultAvatar}
                                             src={props.user && props.user.avatar ? props.user.avatar : defaultAvatar}
                                             className="rounded-circle user-img border-radius-10"
                                             alt={props.currentUser.userName}/>
                                        <span className="hidden-xs"> {getLabel(47)}, {props.currentUser.userName}</span>
                                        {props.currentUser.username}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="" className="nav-link">
                                        {getLabel(42)}
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link to="/goals" className="nav-link">*/}
                                {/*        {getLabel(259)}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link to="/my-stuff" className="nav-link">
                                        {getLabel(43)}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/audiences" className="nav-link">
                                        {getLabel(45)}
                                    </Link>
                                </li>

                                <li className="nav-item mobile-menu">
                                  <Link to="/blog" className="nav-link">
                                    {getLabel(37)}
                                  </Link>
                                </li>
                                {/*<li className="nav-item mobile-menu">
                                    <Link to="/community" className="nav-link">
                                        {getLabel(38)}
                                    </Link>
                                </li>*/}
                                <li className="nav-item mobile-menu">
                                    <Link to="/pollies" className="nav-link">
                                        {getLabel(39)}
                                    </Link>
                                </li>
                                <li className="nav-item mobile-menu">
                                    <Link to="/editor" className="nav-link">
                                        {getLabel(40)}
                                    </Link>
                                </li>
                                <li className="nav-item mobile-menu">
                                    <Link to="/members" className="nav-link">
                                        {getLabel(41)}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/settings" className="nav-link">
                                        <i className="ion-gear-a"/>&nbsp;{getLabel(44)}
                                    </Link>
                                </li>

                                {
                                    isAdmin ?
                                        <li className="nav-item">
                                            <Link to="/admin/setting" className="nav-link">
                                                <i className="ion-admin-a"/>&nbsp;{getLabel(256)}
                                            </Link>
                                        </li> : null
                                }

                                <li className="nav-item">
                                    <a onClick={props.onClickLogout} className="nav-link">
                                        {getLabel(46)}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {/*<li className="hidden-sm-up search-mobile">*/}
                    {/*<Link to="#" className="badge search-badge text-whitewaves-effect waves-light">*/}
                    {/*<i className="fa fa-search  text-white"/>*/}
                    {/*</Link>*/}
                    {/*</li>*/}

                </ul>

            </div>
        );
    }

    return null;
};

class Header extends React.Component {
    constructor() {
        super();

        this.state = {
            anonymousModal: false,
            mode:false,
            image: null,
            dropDown: false
        };
        const user = Object.assign({}, this.state);
        this.dropDownToggle = this.dropDownToggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        if (this.props.user) {
            Object.assign(this.state, {
                image: this.props.user.avatar || ''
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            Object.assign(this.state, {
                image: this.props.user.avatar || ''
            });
        }

        if (nextProps.currentUser && nextProps.currentUser.userName && !nextProps.user.fetching && !nextProps.user.fetched) {
            this.props.fetchUserAvatar(nextProps.currentUser.userName);
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(localStorage.getItem('AnonymousMode')) ? "#272d2d" :"#edf1f1"
        const modeOfUser = JSON.parse(localStorage.getItem('AnonymousMode'));
        this.props.onAnonymousMode(modeOfUser ? modeOfUser : false);
            this.setState({
                mode: modeOfUser ? modeOfUser : false
            })
        document.body.addEventListener('click', (e) => {
            const target = e.target
            const controlsicon = document.getElementById('menu-icon');
            if (target !== controlsicon) {
                this.setState({
                    dropDown: false,
                })
            }
        }, false)
    }

    onChange = () => {
        localStorage.setItem('AnonymousMode', JSON.stringify(!this.state.mode))
        document.body.style.backgroundColor = !this.state.mode ? "#272d2d" :"#edf1f1"
        this.props.onAnonymousMode(!this.state.mode);
        this.setState({
            anonymousModal:false,
            mode: !this.state.mode
        })
    }

    anonymousToggle = () => {
        this.setState({
            anonymousModal: !this.state.anonymousModal,
        })
    }

    dropDownToggle() {
        this.setState({
            dropDown: !this.state.dropDown
        })
    }

    render() {
        const path = this.props.location && this.props.location.pathname === '/register';
        return (
            <div>
                {
                    this.state.anonymousModal &&
                    <AnonymousModeModel
                        onChange={this.onChange}
                        anonymousModal={this.state.anonymousModal}
                        anonymousToggle={this.anonymousToggle}
                    />
                }
            <nav id="navbar" className={`${path && 'navbar-full '} navbar-light${this.state.mode ?"-dark":""} navbar mobile-navbar`} style={{height:"72px"}}>
                <div className={`container`}>
                    <a href="/" className={`navbar-brand${this.state.mode ? "-dark" : ""}`}>
                        <img className="logo" src="./images/logo.svg" title="politiq" alt=""/>
                    </a>

                    <LoggedOutView currentUser={this.props.currentUser} mode={this.state.mode}/>
                    <LoggedInView currentUser={this.props.currentUser} user={this.props.user}
                                  onClickLogout={this.props.onClickLogout} dropDown={this.state.dropDown}
                                  dropDownToggel={this.dropDownToggle} anonymousToggle={this.anonymousToggle} mode={this.props.anonymousMode}/>
                </div>
            </nav>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
