import agent from '../agent'
import {
  APP_LOAD,
  APP_LOADING,
  REDIRECT,
  LOGOUT,
  SET_IS_AUTHORIZED,
  FETCH_USER_SESSION_STARTED,
  FETCH_USER_SESSION_FINISHED
} from "../constants/actionTypes"

export const logout = (payload) => ({
  type: LOGOUT,
  payload
})

export const onClickLogout = (dispatch) => () => {
  localStorage.removeItem("AnonymousMode")
  agent.Auth.logout().then(res => {
    dispatch(logout(res));
    dispatch(setIsAuthorized(false));
  })
}

export const redirect = (payload) => ({
  type: REDIRECT,
  payload
})

export const noLocation = (dispatch) => () => {
  dispatch(redirect('settings'));
}

export const appLoading = () => ({
  type: APP_LOADING
})

export const appLoadSuccess = (payload) => ({
  type: APP_LOAD,
  payload: payload.res,
  token: payload.token,
  skipTracking: payload.skipTracking
})

export const appLoadFailed = (payload) => ({
  type: APP_LOAD,
  payload: payload.err,
  skipTracking: payload.skipTracking
})

export const fetchUserSessionStarted = () => ({
  type: FETCH_USER_SESSION_STARTED
})

export const fetchUserSessionFinished = () => ({
  type: FETCH_USER_SESSION_FINISHED
})

export const setIsAuthorized = (payload) => ({
  type: SET_IS_AUTHORIZED,
  payload
})

export const onLoad = (dispatch) => (token) => {
  dispatch(fetchUserSessionStarted());
  dispatch(appLoading());
  agent.Auth.current().then(res => {
    dispatch(appLoadSuccess({ res, token, skipTracking: true }));
    dispatch(setIsAuthorized(true));
    dispatch(fetchUserSessionFinished());
    localStorage.setItem("viewMyPostsAndComments", JSON.stringify(res.viewMyPostsAndComments))
    localStorage.setItem("replyMyPostsAndComments", JSON.stringify(res.replyMyPostsAndComments))
    localStorage.setItem("viewMyVotes", JSON.stringify(res.viewMyVotes))
  }, err => {
    dispatch(fetchUserSessionFinished());
    dispatch(setIsAuthorized(false));
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("AnonymousMode")
    dispatch(redirect('/login'));
    dispatch(appLoadFailed({ err, skipTracking: true }));
  })
}

export const onRedirect = (dispatch) => (payload) => {
  dispatch(redirect(payload));
}
