import {
    APP_LOAD,
    APP_LOADING,
    REDIRECT,
    SETTINGS_LOADED,
    LOGOUT,
    POST_SUBMITTED,
    SETTINGS_SAVED,
    SETTINGS_UNLOADED,
    LOGIN,
    REGISTER,
    DELETE_POST,
    REMOVE_POST,
    POST_PAGE_UNLOADED,
    EDITOR_PAGE_UNLOADED,
    HOME_PAGE_UNLOADED,
    PROFILE_PAGE_UNLOADED,
    PROFILE_FAVORITES_PAGE_UNLOADED,
    SETTINGS_PAGE_UNLOADED,
    LOGIN_PAGE_UNLOADED,
    REGISTER_PAGE_UNLOADED,
    UPDATE_CURRENT_USER,
    UPDATE_CURRENT_USER_ERROR
} from '../constants/actionTypes';

const defaultState = {
    appName: 'Politiq',
    token: null,
    appLoaded: false,
    userLoaded: false,
    appLoading: false,
    settingsLoaded: false,
    fetchMentionFinished: false,
    viewChangeCounter: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOADING:
            return {
                ...state,
                appLoading: true,
                appLoaded: false
            }
        case APP_LOAD:
            return {
                ...state,
                token: action.token || null,
                appLoaded: true,
                appLoading: false,
                userLoaded: action.payload ? true : false,
                currentUser: action.payload ? action.payload : null
            };
        case REDIRECT:
            return {...state, redirectTo: action.payload ? action.payload : null};
        case SETTINGS_LOADED:
            return {
                ...state,
                settingsLoaded: true
            }
        case SETTINGS_UNLOADED:
            return {
                ...state,
                settingsLoaded: false
            }
        case LOGOUT:
            return {
                ...state,
                redirectTo: '/login',
                token: null,
                currentUser: null,
                appLoaded: false,
                userLoaded: false,
                appLoading: false
            };
        case POST_SUBMITTED:
            const redirectUrl = action?.payload?.article ? `article/${action?.payload?.article?.slug}` : '';
            return {...state, redirectTo: redirectUrl};
        case SETTINGS_SAVED:
            return {
                ...state
            };
        case REGISTER:
            return {
                ...state
            };
        case UPDATE_CURRENT_USER_ERROR:
            return {
                updateError: action.payload && action.payload.responseStatus && action.payload.responseStatus.message
            };
        case UPDATE_CURRENT_USER:
            const {displayLocation = "", displayName = "", userName = "", firstName = "", lastName = "", email = "", bio = "", gender = ""} = state.currentUser || {}
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    displayName: action.payload.displayName != null && action.payload.displayName !== '' ? action.payload.displayName : displayName || "",
                    displayLocation: action.payload.displayLocation != null && action.payload.displayLocation !== '' ? action.payload.displayLocation : displayLocation || "",
                    userName: action.payload.userName != null && action.payload.userName !== '' ? action.payload.userName : userName || "",
                    firstName: action.payload.firstName != null && action.payload.firstName !== '' ? action.payload.firstName : firstName || "",
                    lastName: action.payload.lastName != null && action.payload.lastName !== '' ? action.payload.lastName : lastName || "",
                    email: action.payload.email != null && action.payload.email !== '' ? action.payload.email : email || "",
                    bio: action.payload.bio != null && action.payload.bio !== '' ? action.payload.bio : bio || "",
                    gender: action.payload.gender || "",
                    replyMyPostsAndComments: action.payload.replyMyPostsAndComments,
                    viewMyPostsAndComments: action.payload.viewMyPostsAndComments,
                    sendMeDirectMessage: action.payload.sendMeDirectMessage,
                    mentionMe: action.payload.mentionMe,
                    viewMyProfile: action.payload.viewMyProfile,
                    viewMyVotes: action.payload.viewMyVotes,
                    hasVoterLocations: action.payload.hasVoterLocations,
                    referToMeAs: action.payload.referToMeAs || "",
                    genderText: action.payload.genderText || ""
                },
                userLoaded: true
            };
      case LOGIN:
            return {
                ...state,
                redirectTo: action.error ? null : action.pollyLogin ? action.pathname : action.payload.bannedStatus === 1 ? "/user-banned" : '/',
                token: action.error ? null : action.payload.bearerToken,
                currentUser: {
                    ...state.currentUser,
                    bearerToken: action.error ? null :action.payload.bearerToken != null && action.payload.bearerToken !== '' ? action.payload.bearerToken : "",
                    meta: action.error ? null :action.payload.meta != null && action.payload.meta !== '' ? action.payload.meta : "",
                    permissions: action.error ? null :action.payload.permissions != null && action.payload.permissions !== '' ? action.payload.permissions : "",
                    profileUrl: action.error ? null :action.payload.profileUrl != null && action.payload.profileUrl !== '' ? action.payload.profileUrl : "",
                    referrerUrl: action.error ? null :action.payload.referrerUrl != null && action.payload.referrerUrl !== '' ? action.payload.referrerUrl : "",
                    refreshToken: action.error ? null :action.payload.refreshToken != null && action.payload.refreshToken !== '' ? action.payload.refreshToken : "",
                    responseStatus: action.error ? null :action.payload.responseStatus != null && action.payload.responseStatus !== '' ? action.payload.responseStatus : "",
                    roles: action.error ? null :action.payload.roles != null && action.payload.roles !== '' ? action.payload.roles : "",
                    sessionId: action.error ? null :action.payload.sessionId != null && action.payload.sessionId !== '' ? action.payload.sessionId : "",
                    userId: action.error ? null :action.payload.userId != null && action.payload.userId !== '' ? action.payload.userId : "",
                },
            };
        case DELETE_POST:
            return {...state, redirectTo: '/'};
        case REMOVE_POST:
            return {
                ...state,
            };
        case POST_PAGE_UNLOADED:
        case EDITOR_PAGE_UNLOADED:
        case HOME_PAGE_UNLOADED:
        case PROFILE_PAGE_UNLOADED:
        case PROFILE_FAVORITES_PAGE_UNLOADED:
        case SETTINGS_PAGE_UNLOADED:
        case LOGIN_PAGE_UNLOADED:
        case REGISTER_PAGE_UNLOADED:
            return {...state, viewChangeCounter: state.viewChangeCounter + 1};
        default:
            return state;
    }
};
