import {
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  ASYNC_START,
  UPDATE_FIELD_AUTH,
  SET_IS_AUTHORIZED,
  FETCH_USER_SESSION_STARTED,
  FETCH_USER_SESSION_FINISHED
} from '../constants/actionTypes';

const initialState = {
  isAuthorized: false,
  hasErrors: false,
  finished: false,
  started: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_AUTHORIZED:
      return {
        ...state,
        isAuthorized: action.payload
      };
    case FETCH_USER_SESSION_STARTED:
      return {
        ...state,
        finished: false,
        started: true,
      };
    case FETCH_USER_SESSION_FINISHED:
      return {
        ...state,
        finished: true,
        started: false,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? (action.payload || {}).responseStatus : null
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER) {
        return { ...state, inProgress: true };
      }
      break;
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
