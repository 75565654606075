import {
  EDITOR_PAGE_LOADED,
  EDITOR_PAGE_UNLOADED,
  POST_SUBMITTED,
  ASYNC_START,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_FIELD_EDITOR
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case EDITOR_PAGE_LOADED:
      return {
        ...state,
        postSlug: action.payload ? action.payload[0].slug : '',
        title: action.payload ? action.payload[0].title : '',
        description: action.payload ? action.payload[0].description : '',
        body: action.payload ? action.payload[0].body : '',
        tagInput: '',
        tagList: action.payload ? action.payload[0].tags : [],
        id: action.payload ? action.payload[0].id: '',
        author: action.payload ? action.payload[0].author: '',
        created: action.payload ? action.payload[0].created: '',
        replyMyPostsAndComments: action.payload ? action.payload[0].replyMyPostsAndComments : '',
        viewMyPostsAndComments: action.payload ? action.payload[0].viewMyPostsAndComments : '',
      };
    case EDITOR_PAGE_UNLOADED:
      return {};
    case POST_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload?.responseStatus?.errors : null,
        errorMessage:action.error ?  action.payload?.responseStatus?.message || '' : '',
        postSlug: action.error ? state.postSlug :'',
        title: action.error ? state.title :'',
        description: action.error ? state.description :'',
        body: action.error ? state.body :'',
        tagInput: action.error ? state.tagInput :'',
        tagList: action.error ? state.tagList : [],
        author: action.error ? state.author :{},
        created: action.error ? state.created :'',
      };
    case ASYNC_START:
      if (action.subtype === POST_SUBMITTED) {
        return { ...state, inProgress: true };
      }
      break;
    case ADD_TAG:
      return {
        ...state,
        tagList: (state.tagList || []).concat([state.tagInput]),
        tagInput: ''
      };
    case REMOVE_TAG:
      return {
        ...state,
        tagList: state.tagList.filter(tag => tag !== action.tag)
      };
    case UPDATE_FIELD_EDITOR:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
