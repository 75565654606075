import {
  FETCH_MENTION_STARTED,
  FETCH_MENTION_FINISHED
} from '../constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_MENTION_STARTED:
      return { ...state }
    case FETCH_MENTION_FINISHED: 
      return {
          ...state,
			    mentions: action.payload
      }
    default:
      return state;
  };
};