import {
  GOALS_COMMENT_CREATED,
  GOALS_COMMENTS_LOADED,
  GOALS_COMMENTS_UNLOADED
} from '../constants/actionTypes';

export default (state = { comments: {} }, action) => {
  switch (action.type) {
    case GOALS_COMMENT_CREATED:
      return {
        ...state,
        comment: action.payload
      };
    case GOALS_COMMENTS_LOADED:
      return {
        ...state,
        comments: action.payload
      };
    case GOALS_COMMENTS_UNLOADED:
      return {};
    default:
      return state;
  }
};
