import {ON_CHANGE_QUICK_POST, CLEAR_QUICK_POST} from '../constants/actionTypes';

const initialState = {
  body: null
}

export default(state=initialState, action) => {
  switch (action.type) {
    case ON_CHANGE_QUICK_POST:
      return {
        ...state,
        body: action.payload.body
      };
    case CLEAR_QUICK_POST:
      return {
        ...state,
        body: null
      };
    default:
      return state;
  }
};
