export default function swDev()
{
    if(navigator.serviceWorker){
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`).then((registration)=>{
            console.log('SW registration');
        }).catch((e)=>{
            console.log(e);
        })
    }
}
